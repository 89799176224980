import { css } from 'styled-components';

export default css`
  .gm-button-space {
    display: flex;
    justify-content: end;
  }

  .gm-button {
    border-radius: 2rem;
    margin: 5px;
    padding: 6px 50px;
    text-transform: capitalize;
  }

  .gm-button-primary {
    color: #124e88;
  }

  .gm-button-primary:hover {
    color: #ffffff;
    background-color: #607d8b;
    text-decoration: none;
  }

  .gm-button-secondary {
    color: #ffffff;
    border: 1px solid #938f99;
  }
`;
