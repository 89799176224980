import { useEffect, useState } from 'react';
import { debounce } from 'lodash';

export const useDebounce = (value, duration = 200) => {
  const [internalValue, setInternalValue] = useState(value);

  const debouncedSet = debounce(() => {
    setInternalValue(value);
  }, duration);

  useEffect(() => {
    debouncedSet();
    return () => {
      debouncedSet.cancel();
    };
  }, [debouncedSet]);

  return internalValue;
};
