import { Form } from 'connex-cds';
import * as Yup from 'yup';

export default {
  path: 'airSensorParameters',
  labelStringId: 'airSensorParameters',
  testId: 'airSensorParameters',
  keyPath: 'crn',
  validationSchema: Yup.object().shape({
    JSONParams: Yup.string(),
    FirmwareVersion: Yup.string(),
    SensorSerialNumber: Yup.string(),
    AcquisitionScriptVersion: Yup.string(),
    AirComputationCalibrationModelVersion: Yup.string(),
    PAUMacAddress: Yup.string(),
  }),

  fields: [
    {
      path: 'FirmwareVersion',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'FirmwareVersion',
      testId: 'FirmwareVersion',
      multiline: true,
    },
    {
      path: 'SensorSerialNumber',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'SensorSerialNumber',
      testId: 'SensorSerialNumber',
      multiline: true,
    },
    {
      path: 'AcquisitionScriptVersion',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'AcquisitionScriptVersion',
      testId: 'AcquisitionScriptVersion',
      multiline: true,
    },
    {
      path: 'AirComputationCalibrationModelVersion',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'AirComputationCalibrationModelVersion',
      testId: 'AirComputationCalibrationModelVersion',
      multiline: true,
    },
    {
      path: 'PAUMacAddress',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'PAUMacAddress',
      testId: 'PAUMacAddress',
      multiline: true,
    },
    {
      path: 'JSONParams',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'JSONParams',
      testId: 'JSONParams',
      multiline: true,
    },
  ],
};
