import React from 'react';
import { omit } from 'lodash';
import { any, bool, func, string } from 'prop-types';
import { Checkbox } from '@cai/material';

const CheckboxBase = ({ name, value, disabled, onChange, isSubmitting, labelStringId, ...otherProps }) => {
  const handleChange = newValue => {
    onChange(name, newValue);
  };
  return (
    <Checkbox
      name={name}
      value={value}
      token={labelStringId}
      onChange={handleChange}
      disabled={disabled || isSubmitting}
      {...omit(otherProps, ['setFieldTouched', 'setFieldValue', 'fullWidth'])}
    />
  );
};

CheckboxBase.propTypes = {
  name: string.isRequired,
  value: any.isRequired,
  disabled: bool,
  onChange: func.isRequired,
  isSubmitting: bool,
  labelStringId: string.isRequired,
};

CheckboxBase.defaultProps = {
  disabled: false,
  isSubmitting: false,
  value: false,
};

export default CheckboxBase;
