import { http } from 'connex-cds';

export default {
  getGroups: ({ entityRef }) => http.get({ apiName: 'assurance', path: `/${entityRef}/device-groups` }),
  postGroup: ({ entityRef, data }) => http.post({ apiName: 'assurance', path: `/${entityRef}/device-groups`, data }),
  updateGroup: ({ entityRef, crn, data }) =>
    http.patch({ apiName: 'assurance', path: `/${entityRef}/device-groups/${crn}`, data }),
  deleteGroup: ({ entityRef, crn }) =>
    http.delete({ apiName: 'assurance', path: `/${entityRef}/device-groups/${crn}` }),
};
