import { DataDenseGrid } from '@cai/material';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const DataGridStyled = styled(DataDenseGrid)`
  ${({ rowHeight }) => css`
    .MuiDataGrid-main {
      border-radius: 10px;
    }
    .MuiDataGrid-row {
      min-height: ${rowHeight}px !important;
      max-height: ${rowHeight}px !important;

      .MuiDataGrid-cell {
        min-height: ${rowHeight}px !important;
        max-height: ${rowHeight}px !important;
      }
    }
  `}
`;

export const SkeletonContainer = styled.div`
  ${() => css`
    .header-skeleton {
      border-radius: 12px;
      margin-bottom: 14px;
      height: 20px;
    }

    .rows-skeleton-container {
      display: flex;
      flex-direction: column;
      gap: 1px;

      .row-skeleton {
        height: 54px;

        &:first-child {
          border-radius: 10px 10px 0 0;
        }

        &:last-child {
          border-radius: 0 0 10px 10px;
        }
      }
    }
  `}
`;
