import { Form } from 'connex-cds';
import * as Yup from 'yup';

export default {
  path: 'displayFirmwareParameters',
  labelStringId: 'displayFirmwareParameters',
  testId: 'displayFirmwareParameters',
  keyPath: 'crn',
  validationSchema: Yup.object().shape({
    Version: Yup.string(),
    Priority: Yup.number(),
    Critical: Yup.boolean(),
  }),

  fields: [
    {
      path: 'Version',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'Version',
      testId: 'Version',
      multiline: true,
    },
    {
      path: 'Priority',
      dataType: Form.FIELD_TYPES.number,
      labelStringId: 'Priority',
      testId: 'Priority',
      multiline: true,
    },
    {
      path: 'Critical',
      dataType: Form.FIELD_TYPES.boolean,
      labelStringId: 'Critical',
      testId: 'Critical',
      multiline: true,
    },
  ],
};
