import * as yup from 'yup';

import { STATUS_OPTIONS } from '../../../../constants';

export const validationSchema = values =>
  yup.object().shape({
    id: yup.string().required('required'),
    name: yup.string().required('required'),
    groupName: yup.string(),
    reportName: yup.string(),
    collectionName: yup.string(),
    icon: yup.string(),
    status: yup.string(),
    toolbar: yup.bool(),
    tooltip: yup.bool(),
    tabs: yup.bool(),
  });

export const initialValues = {
  id: '',
  name: '',
  groupName: '',
  reportName: '',
  collectionName: '',
  icon: '',
  status: STATUS_OPTIONS[0].value,
  toolbar: false,
  tooltip: false,
  tabs: false,
};
