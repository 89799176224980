import { Button as CaiButton } from '@cai/material';

import { CaiThemeProvider } from '../../providers';

const Button = props => {
  return (
    <CaiThemeProvider>
      <CaiButton {...props} />
    </CaiThemeProvider>
  );
};

export default Button;
