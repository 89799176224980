import { TableLayout, SearchFilter, Button, CustomIcon, EntityTable, DialogManager } from '../../commons';
import { useListInsightsWorkbooks } from '../../query-hooks/insights';
import { useTableLogic } from '../../hooks';
import { getInsightsSettingsListColumns } from './tableConfig';
import { DIALOG_TYPES } from '../../constants';
import AddInsightsWorkbookDialog from './components/add-insights-workbook-dialog';
import EditInsightsWorkbookDialog from './components/edit-insights-workbook-dialog';
import DeleteInsightsWorkbookDialog from './components/delete-insights-workbook-dialog';

export const InsightsSettings = () => {
  const filteredPropsArr = ['id', 'name'];
  const {
    setQuery,
    currentEntity,
    dialog,
    setDialog,
    isLoading,
    refetch,
    filteredData,
    filteredDataStatus: { hasFilteredData, isFilteredData, isFilteredDataEmpty },
    entityColumns,
    resetDialogs,
    handleOnRowClick,
    setIsOnEditDialog,
    totalRows,
  } = useTableLogic(useListInsightsWorkbooks, getInsightsSettingsListColumns, filteredPropsArr);

  const AddButton = () => {
    return (
      <Button
        variant="top-level"
        token="insightsWorkbooks"
        icon={<CustomIcon iconName="addOutlinedIcon" />}
        onClick={() => setDialog(DIALOG_TYPES.ADD)}
      />
    );
  };

  return (
    <TableLayout.ListLayoutContainer>
      <TableLayout.TableControls>
        {(hasFilteredData || (isFilteredDataEmpty && isFilteredData)) && (
          <>
            <SearchFilter onSearchChange={setQuery} disabled={isLoading} />
            <AddButton />
          </>
        )}
      </TableLayout.TableControls>
      <TableLayout.TableBody>
        <EntityTable
          isLoading={isLoading}
          columns={entityColumns}
          rows={filteredData}
          getRowId={row => row.crn}
          onRowClick={handleOnRowClick}
          actionButton={isFilteredDataEmpty && <AddButton />}
          isSearching={isFilteredData}
          noDataStringId={'insightsWorkbooks_noData_text'}
          onRefreshData={() => refetch()}
          totalRows={totalRows}
        />
      </TableLayout.TableBody>
      <DialogManager screen={dialog}>
        <AddInsightsWorkbookDialog identifier={DIALOG_TYPES.ADD} onClose={resetDialogs} />
        <EditInsightsWorkbookDialog
          identifier={DIALOG_TYPES.EDIT}
          itemData={currentEntity}
          onClose={resetDialogs}
          handleOnDelete={() => {
            setIsOnEditDialog(true);
            setDialog(DIALOG_TYPES.DELETE);
          }}
        />
        <DeleteInsightsWorkbookDialog
          identifier={DIALOG_TYPES.DELETE}
          onClose={resetDialogs}
          itemData={currentEntity}
        />
      </DialogManager>
    </TableLayout.ListLayoutContainer>
  );
};
