import { Localization } from 'connex-cds';

import { ReactComponent as NoDataImage } from '../../../assets/no-data-image.svg';
import { ReactComponent as SearchEmptyImage } from '../../../assets/search-empty-image.svg';
import { NoDataContainer, NoDataContent, NoDataHeader, NoDataText } from './styles';

const NoData = ({ actionButton, isSearching, noDataStringId }) => {
  const headerStringId = isSearching ? 'general_noSearchResults_header' : 'general_noData_header';
  const textStringId = isSearching ? 'general_noSearchResults_text' : noDataStringId;

  return (
    <NoDataContainer>
      {isSearching ? <SearchEmptyImage /> : <NoDataImage />}
      <NoDataContent isSearching={isSearching}>
        <NoDataHeader>
          <Localization.Translate stringId={headerStringId} />
        </NoDataHeader>
        {textStringId && (
          <NoDataText>
            <Localization.Translate stringId={textStringId} />
          </NoDataText>
        )}
      </NoDataContent>
      {actionButton && !isSearching && <>{actionButton}</>}
    </NoDataContainer>
  );
};

export default NoData;
