import React from 'react';
import { Field, Form } from 'formik';

import { Grid } from '@mui/material';

import { SelectWithFormikField, TextInputWithFormikField, CheckboxWithFormikField } from '../../../../commons';
import { DIALOG_TYPES } from '../../../../constants';
import { InsightsWorkbookProfileTabContainer } from './styles';

const InsightsWorkbookProfileTab = ({ mode, statusOptions }) => {
  return (
    <InsightsWorkbookProfileTabContainer>
      <Form>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <Field
              fullWidth
              labelStringId="id"
              component={TextInputWithFormikField}
              name="id"
              disabled={mode === DIALOG_TYPES.EDIT}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Field fullWidth labelStringId="name" component={TextInputWithFormikField} name="name" required />
          </Grid>
          <Grid item xs={6}>
            <Field fullWidth labelStringId="groupName" component={TextInputWithFormikField} name="groupName" />
          </Grid>
          <Grid item xs={6}>
            <Field fullWidth labelStringId="reportName" component={TextInputWithFormikField} name="reportName" />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              labelStringId="collectionName"
              component={TextInputWithFormikField}
              name="collectionName"
            />
          </Grid>
          <Grid item xs={6}>
            <Field fullWidth labelStringId="icon" component={TextInputWithFormikField} name="icon" />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              labelStringId="status"
              component={SelectWithFormikField}
              name="status"
              options={statusOptions}
              required
              sortOptionsByTranslationKey
            />
          </Grid>
          <Grid item container xs={6} spacing={1}>
            <Grid item xs={12}>
              <Field fullWidth labelStringId="showToolbar" component={CheckboxWithFormikField} name="toolbar" />
            </Grid>
            <Grid item xs={12}>
              <Field fullWidth labelStringId="showTooltip" component={CheckboxWithFormikField} name="tooltip" />
            </Grid>
            <Grid item xs={12}>
              <Field fullWidth labelStringId="showTabs" component={CheckboxWithFormikField} name="tabs" />
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </InsightsWorkbookProfileTabContainer>
  );
};

export default InsightsWorkbookProfileTab;
