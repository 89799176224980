import { useEntity } from 'connex-cds';
import React from 'react';
import { useNavigate } from 'react-router';
import { matchPath, Outlet, useLocation } from 'react-router-dom';

export const RedirectToEntity = () => {
  const { entityRef } = useEntity();
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    if (!entityRef) return;

    // if the entityRef changes navigate to the same route with the new entityRef
    if (entityRef && !location.pathname.includes(entityRef)) {
      const matchWithEntityRef = matchPath(`/:entityRef/*`, location.pathname);

      // Commented out to handle issues with users in nested forms that causes breaking changes when switching companies
      // if (matchWithEntityRef?.params?.entityRef) {
      //   const newPathname = matchWithEntityRef.pathname.replace(matchWithEntityRef.params.entityRef, entityRef);
      //   navigate(newPathname);
      //   return;
      // }

      navigate(`/${entityRef}`);
    }
  }, [entityRef, location, navigate]);

  return <Outlet />;
};
