import { Form } from 'connex-cds';
import * as Yup from 'yup';
import { CustomButton } from '../components/CustomButton';
import styled from 'styled-components';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export default {
  path: 'defaultConfig',
  labelStringId: 'defaultConfig',
  testId: 'defaultConfig',
  keyPath: 'crn',
  validationSchema: Yup.object().shape({
    deviceType: Yup.string(),
    default: Yup.boolean(),
  }),

  fields: [
    {
      path: 'deviceType',
      valuePath: 'deviceType',
      displayPath: 'label',
      dataType: Form.FIELD_TYPES.string,
      listValuePath: 'crn',
      listDisplayPath: 'label',
      labelStringId: 'deviceType',
      testId: 'deviceType',
      key: 'deviceType',
      formatter: props => (
        <div>
          <Styled>
            {props.row.map(config => {
              return <p key={config?.crn}>{config?.deviceType}</p>;
            })}
          </Styled>
        </div>
      ),
    },
    {
      path: 'default',
      valuePath: 'default',
      dataType: Form.FIELD_TYPES.string,
      listDisplayPath: 'name',
      labelStringId: 'default',
      testId: 'default',
      key: 'default',
      formatter: props => (
        <div>
          <Styled>
            {props.row.map(config => {
              return <CustomButton config={config} key={config?.crn} />;
            })}
          </Styled>
        </div>
      ),
    },
  ],
};
