import { useListInsightsWorkbooks } from '../../query-hooks/insights';
import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import CardList from './components/CardList';

export const InsightsList = () => {
  const { data } = useListInsightsWorkbooks();

  const [groupedItems, setGroupedItems] = useState([]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const reducedGroupedItems = data?.reduce((acc, item) => {
      const { groupName } = item;
      if (!acc[groupName]) {
        acc[groupName] = [];
      }
      acc[groupName].push(item);

      return acc;
    }, {});

    const newGroupedItems = Object.entries(reducedGroupedItems).map(([key, value]) => {
      value.sort((a, b) => {
        return a.name < b.name ? -1 : 1;
      });
      return {
        groupName: key,
        items: value,
      };
    });

    newGroupedItems.sort((a, b) => {
      return a.groupName < b.groupName ? -1 : 1;
    });

    setGroupedItems(newGroupedItems);
  }, [data]);

  return (
    <>
      {groupedItems.map(({ groupName, items }) => {
        return (
          <div className={'insights-group'}>
            <Typography className={'insights-group-title'} variant={'h3'}>
              {groupName}
            </Typography>
            <CardList items={items} />
          </div>
        );
      })}
    </>
  );
};
