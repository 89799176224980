import { useState } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { ConfirmationDialog } from '../../../../commons';
import { trimWithDots } from '../../../../util/strings';
import { useDeleteInsightsWorkbook } from '../../../../query-hooks/insights';

const DeleteInsightsWorkbookDialog = ({ onClose, itemData }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();
  const { mutateAsync: deleteInsightsWorkbook } = useDeleteInsightsWorkbook();

  const handleDelete = async () => {
    try {
      if (itemData?.crn) {
        await deleteInsightsWorkbook({ crn: itemData.crn });
        notification.success(translateMessage('insightsWorkbooks_notification_insightsWorkbookDeleted'));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnClose = (isDeleteSuccess = false) => {
    setIsOpen(false);
    onClose(isDeleteSuccess);
  };

  return (
    <ConfirmationDialog
      open={isOpen}
      onConfirm={handleDelete}
      onClose={handleOnClose}
      confirmText="delete"
      color="delete"
    >
      <h3 className="delete-title">
        <Localization.Translate stringId="insightsWorkbooks_deleteDialog_title" />
      </h3>
      <p className="delete-description">
        <Localization.Translate
          stringId="insightsWorkbooks_deleteDialog_description"
          values={{
            strong: chunks => <strong>{trimWithDots(chunks, 100)}</strong>,
            name: itemData?.name,
          }}
        />
      </p>
    </ConfirmationDialog>
  );
};

export default DeleteInsightsWorkbookDialog;
