import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';

import iconNames from './iconList';
import IconStyles from './styles';

const Icon = ({ iconName, className: classNameProp = '', ...props }) => {
  const IconComponent = iconNames[iconName];
  if (!IconComponent) return null;
  return <IconComponent className={cn('icon', classNameProp)} {...props} />;
};

const Styled = styled(Icon)`
  ${IconStyles}
`;

Icon.propTypes = {
  iconName: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default Styled;
