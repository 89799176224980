import styled from 'styled-components';
import style from '../../utils/style';
import MonacoEditor from '@uiw/react-monacoeditor';
import { Content } from 'antd/lib/layout/layout';
import { useState } from 'react';
import { useEffect } from 'react';

const Styled = styled.code`
  ${style}
`;

export const StepTwo = ({ data, setEditedCommand, setNextBtnDisabled }) => {
  const [command, setCommand] = useState('');
  const options = {
    contextmenu: false,
    autoIndent: 'advanced',
    theme: 'vs-dark',
    readOnly: false,
    scrollBeyondLastLine: false,
    minimap: { enabled: false },
    lineNumbers: 'off',
    fontSize: 15,
  };

  useEffect(() => {
    setCommand(data?.command);
    setNextBtnDisabled(false);
  }, [data, setNextBtnDisabled]);

  const handleChange = newValue => {
    setEditedCommand(newValue);
  };

  return (
    <Styled>
      <Content>
        {command?.length > 0 && (
          <MonacoEditor
            language="xml"
            value={command}
            height="30vh"
            onChange={handleChange.bind(this)}
            options={options}
          />
        )}
      </Content>
    </Styled>
  );
};
