import { Form } from 'connex-cds';
import * as Yup from 'yup';

export default {
  path: 'slumMGRParameters',
  labelStringId: 'slumMGRParameters',
  testId: 'slumMGRParameters',
  keyPath: 'crn',
  validationSchema: Yup.object().shape({
    isSlumpFilterEnable: Yup.boolean().required('required'),
    pressureBufferSize: Yup.number().required('required'),
    pressureAgeTurnLimit: Yup.number().required('required'),
    pressureBufferAcceptanceSlumpRange: Yup.number().required('required'),
    maximumAverageRPMLimit: Yup.number().required('required'),
    slumpIncreaseAlertThreshold: Yup.number().required('required'),
  }),

  fields: [
    {
      path: 'isSlumpFilterEnable',
      dataType: Form.FIELD_TYPES.boolean,
      labelStringId: 'isSlumpFilterEnable',
      testId: 'isSlumpFilterEnable',
      multiline: true,
    },
    {
      path: 'pressureBufferSize',
      dataType: Form.FIELD_TYPES.number,
      labelStringId: 'pressureBufferSize',
      testId: 'pressureBufferSize',
      multiline: true,
    },
    {
      path: 'pressureAgeTurnLimit',
      dataType: Form.FIELD_TYPES.number,
      labelStringId: 'pressureAgeTurnLimit',
      testId: 'pressureAgeTurnLimit',
      multiline: true,
    },
    {
      path: 'pressureBufferAcceptanceSlumpRange',
      dataType: Form.FIELD_TYPES.number,
      labelStringId: 'pressureBufferAcceptanceSlumpRange',
      testId: 'pressureBufferAcceptanceSlumpRange',
      multiline: true,
    },
    {
      path: 'maximumAverageRPMLimit',
      dataType: Form.FIELD_TYPES.number,
      labelStringId: 'maximumAverageRPMLimit',
      testId: 'maximumAverageRPMLimit',
      multiline: true,
    },
    {
      path: 'slumpIncreaseAlertThreshold',
      dataType: Form.FIELD_TYPES.number,
      labelStringId: 'slumpIncreaseAlertThreshold',
      testId: 'slumpIncreaseAlertThreshold',
      multiline: true,
    },
  ],
};
