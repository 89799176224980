import { Card, Divider, Space } from 'antd';
import { User, Modal } from 'connex-cds';
import { StepOne, StepTwo, StepThree, StepFour } from './steps';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import style from '../utils/style';
import { GMButton } from '../../../cds-labs/GMButton';
import { FormattedMessage } from 'react-intl';
import { Box, Typography } from '@mui/material';

const Styled = styled.div`
  ${style}
`;

export const NewDeviceMessageModal = ({ entityRef, refetchData, setRefetchList }) => {
  const COMPANY_DOMAIN = '@commandalkon.com';
  const { closeModal } = Modal.useModalContext();
  const { user } = User.useUserContext();
  const [validCompanyUser, setValidCompanyUser] = useState(false);
  const [step, setStep] = useState(0);
  const STEPS_TITLES = ['addCommandTitle', 'editCommandTitle', 'selectATruckTitle', 'commandSentTitle'];
  const [cancelBtnText, setCancelBtnText] = useState('cancelBtn');
  const [nextBtnText, setNextBtnText] = useState('selectTruck');
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
  const [refetchtDataCommands, setRefetchtDataCommands] = useState(refetchData);
  const [newDeviceMsgData, setNewDeviceMsgData] = useState({});
  const [editedCommand, setEditedCommand] = useState({});
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    const validateEmailUser = user.email.endsWith(COMPANY_DOMAIN);

    if (validateEmailUser) {
      setValidCompanyUser(true);
    }
  }, [COMPANY_DOMAIN, user.email]);

  useEffect(() => {
    if (step === 0) {
      setCancelBtnText('cancelBtn');
      setNextBtnText('selectTruck');
    }
    if (step === 1) {
      setCancelBtnText('back');
      setNextBtnText('selectTruck');
    }
    if (step === 2) {
      setCancelBtnText('back');
      setNextBtnText('send');
    }
    if (step === 3) {
      setCancelBtnText('addAnotherCommand');
      setNextBtnText('done');
    }
  }, [step]);

  const setNewCommand = data => {
    setNewDeviceMsgData({ ...data });
  };

  const nextStep = () => {
    setRefetchtDataCommands(false);

    if (step === STEPS_TITLES.length - 1) {
      setCancelBtnText('cancelBtn');
      setRefetchList(true);
      return closeModal();
    }

    if (step === 0) {
      return validCompanyUser ? setStep(step + 1) : setStep(step + 2);
    }

    if (step === 1 && editedCommand !== newDeviceMsgData.command) {
      setNewDeviceMsgData({ ...newDeviceMsgData, command: editedCommand });
    }

    if (step === 1) {
      setRefetchtDataCommands(true);
    }

    setStep(step + 1);
  };

  const prevStep = () => {
    setRefetchtDataCommands(false);

    if (step <= 0) {
      setRefetchtDataCommands(true);
      return closeModal();
    }

    if (step === STEPS_TITLES.length - 1) {
      setRefetchtDataCommands(true);
      setRefetchList(true);
      return setStep(0);
    }

    if (step === 2) {
      return validCompanyUser ? setStep(step - 1) : setStep(step - 2);
    }

    setStep(step - 1);
  };

  return (
    <Styled>
      <Card
        title={<FormattedMessage id={`${STEPS_TITLES[step]}`} defaultMessage={`${STEPS_TITLES[step]}`} />}
        className="new-message-form-modal"
      >
        {step === 0 && (
          <StepOne
            entityRef={entityRef}
            validCompanyUser={validCompanyUser}
            setNextBtnDisabled={setNextBtnDisabled}
            setNewCommand={setNewCommand}
            getData={refetchtDataCommands}
          />
        )}
        {step === 1 && validCompanyUser && (
          <StepTwo
            setEditedCommand={setEditedCommand}
            data={newDeviceMsgData}
            setNextBtnDisabled={setNextBtnDisabled}
          />
        )}
        {step === 2 && (
          <StepThree
            entityRef={entityRef}
            setNextBtnDisabled={setNextBtnDisabled}
            setVehicles={setVehicles}
            getData={refetchtDataCommands}
          />
        )}
        {step === 3 && (
          <StepFour
            entityRef={entityRef}
            setNextBtnDisabled={setNextBtnDisabled}
            command={newDeviceMsgData}
            trucks={vehicles}
          />
        )}
      </Card>
      <Divider></Divider>
      <Space style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Box>
          {step === 2 && (
            <Typography variant="subtitle1">
              <FormattedMessage id={'trucksCount'} defaultMessage={'trucksCount'} /> {vehicles.length}
            </Typography>
          )}
        </Box>
        <Box style={{ display: 'flex' }}>
          <GMButton id={cancelBtnText} type="secondary" onClick={prevStep} />
          <GMButton id={nextBtnText} type="primary" onClick={nextStep} disabled={nextBtnDisabled} />
        </Box>
      </Space>
    </Styled>
  );
};
