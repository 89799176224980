import { http } from 'connex-cds';

/**
 * Function for create a new device Config
 */
export const postNewDeviceConfig = async (entityRef, data, deviceData) => {
  return await http
    .post({ apiName: 'assurance', path: `/${entityRef}/device-configs`, data })
    .then(async function async(response) {
      const vehicleDeviceRef = deviceData?.crn;
      const deviceConfigRefs = deviceData?.deviceConfigRefs;
      const newConfigRef = {
        deviceConfigRef: response?.crn,
        entityRef: entityRef,
        default: false,
        id: response?.id,
      };

      if (deviceConfigRefs[response?.deviceType]) {
        deviceConfigRefs[response?.deviceType].push(newConfigRef);
      } else {
        deviceConfigRefs[response?.deviceType] = [newConfigRef];
      }

      await updateDeviceConfigRefs(entityRef, vehicleDeviceRef, deviceConfigRefs);
    })
    .catch(function (error) {
      console.log(error);
    });
};

/**
 * Function for update an existing device Config
 */
export const patchDeviceConfig = async (entityRef, data, configCrn) => {
  return await http
    .patch({ apiName: 'assurance', path: `/${entityRef}/device-configs/${configCrn}`, data })
    .then(window.location.reload())
    .catch(function (error) {
      console.log(error);
    });
};

/**
 * Function for update the vehicles devices on a truck
 */
export const updateDeviceConfigRefs = async (entityRef, vehicleDeviceRef, deviceConfigRefs) => {
  return await http
    .patch({
      apiName: 'assurance',
      path: `/${entityRef}/vehicle-devices/${vehicleDeviceRef}`,
      data: { deviceConfigRefs },
    })
    .then(window.location.reload())
    .catch(function (e) {
      console.log('Error updating the vehicle configRefs: ', e);
    });
};
