import { useState } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import InsightsWorkbookFormView from '../insights-workbook-form-view';
import { initialValues } from '../insights-workbook-form-view/form-config';
import { useCreateInsightsWorkbook } from '../../../../query-hooks/insights';

const AddInsightsWorkbookDialog = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();
  const { mutateAsync: createInsightsWorkbook } = useCreateInsightsWorkbook();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    formikBag.setSubmitting(true);
    try {
      const data = {
        ...values,
      };
      await createInsightsWorkbook({ data });
      notification.success(translateMessage('insightsWorkbooks_notification_insightsWorkbookAdded'));
      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <InsightsWorkbookFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.ADD}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      statusOptions={STATUS_OPTIONS}
    />
  );
};

export default AddInsightsWorkbookDialog;
