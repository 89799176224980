import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { Groups } from '../api';

/**
 * Function for Get the device Groups
 * @author Juan David Reina
 */
export const useDeviceGroups = () => {
  const { entityRef } = useParams();

  if (!entityRef) {
    console.trace();
    return undefined;
  }

  const query = useQuery({
    queryKey: ['listDeviceGroups', entityRef],
    queryFn: () => Groups.getGroups({ entityRef }),
    staleTime: 0,
    retry: 0,
    cacheTime: 0,
  });

  return query;
};

/**
 * Function for Post the device group
 * @author Juan David Reina
 */
export const useStoreNewGroup = (data, entityRef) => {
  const queryClient = useQueryClient();
  const queryKey = ['storeGroups', entityRef];

  const mutation = useMutation({
    mutationFn: data => {
      Groups.postGroup({ ...data });
    },
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ['listDeviceGroups'] });
      }, 1000);
    },
  });

  return mutation;
};

/**
 * Function for Patch/Update a devices group
 * @author Juan David Reina
 */
export const useUpdateGroup = (data, entityRef) => {
  const queryClient = useQueryClient();
  const queryKey = ['updateDeviceGroups', entityRef];

  const mutation = useMutation({
    mutationFn: data => {
      Groups.updateGroup({ ...data });
    },
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ['listDeviceGroups'] });
      }, 1000);
    },
  });

  return mutation;
};

/**
 * Function for delete a devices group
 * @author Juan David Reina
 */
export const useDeleteGroup = (data, entityRef) => {
  const queryClient = useQueryClient();
  const queryKey = ['deleteDeviceGroups', entityRef];

  const mutation = useMutation({
    mutationFn: data => {
      Groups.deleteGroup({ ...data });
    },
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ['listDeviceGroups'] });
      }, 1000);
    },
  });

  return mutation;
};
