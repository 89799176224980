import { Form } from 'connex-cds';
import DynamicComponent from './DynamicComponent';
import * as devicesConfig from '../utils';
import { useGetDeviceConfig } from '../../../query-hooks/devices';
import { useEffect, useState } from 'react';

const DynamicComponentProvider = ({ config, deviceData, closeDrawer, mainComponent }) => {
  const { FormProvider } = Form;
  const deviceConfigRef = config.deviceConfigRef ? config.deviceConfigRef : config.crn;
  const entityRef = config.entityRef;
  const device = useGetDeviceConfig(deviceConfigRef, entityRef);
  const [selectedConfig, setSelectedConfig] = useState(null);

  useEffect(() => {
    let sensorType = '';

    if (device?.data) {
      sensorType = device?.data?.id.replace(/\s/g, '');
    } else {
      sensorType = config.id.replace(/\s/g, '');
    }

    const selectConfig = devicesConfig[sensorType];
    setSelectedConfig(selectConfig);
  }, [config.id, device, selectedConfig]);

  return (
    <>
      {selectedConfig && (
        <FormProvider
          config={selectedConfig}
          deviceConfigData={config}
          data={device?.data ? device?.data : config}
          deviceData={deviceData}
          closeDrawer={closeDrawer}
          defaultConfig={config?.default}
          mainComponent={mainComponent}
        >
          <DynamicComponent />
        </FormProvider>
      )}
    </>
  );
};

export default DynamicComponentProvider;
