import { func, string, any, bool } from 'prop-types';

import { withFormikField } from '../../hoc';
import CheckboxBase from './CheckboxBase';

const Checkbox = props => <CheckboxBase {...props} />;

Checkbox.propTypes = {
  name: string.isRequired,
  value: any.isRequired,
  disabled: bool,
  onChange: func.isRequired,
  isSubmitting: bool,
  labelStringId: string.isRequired,
};

Checkbox.defaultProps = {
  disabled: false,
  isSubmitting: false,
  value: false,
};

export default withFormikField(Checkbox);
