import React from 'react';
import { Formik } from 'formik';

import { DialogActions, DialogContainer, DialogContent, DialogTitle } from '../../../../commons';
import InsightsWorkbookProfileTab from '../insights-workbook-profile-tab';
import { validationSchema } from './form-config';

const InsightsWorkbookFormView = ({ open, onClose, mode, initialValues, onSubmit, statusOptions, handleOnDelete }) => {
  const handleClose = () => {
    onClose();
  };

  const handleCloseDialog = (_e, reason) => {
    if (reason === 'escapeKeyDown') {
      handleClose();
    }
  };

  return (
    <DialogContainer open={open} maxWidth="md" fullWidth onClose={handleCloseDialog}>
      <DialogTitle
        onDelete={handleOnDelete}
        onClose={handleClose}
        mode={mode}
        stringIdToAdd="insightsWorkbooks_addInsightsWorkbooks_title"
        stringIdToEdit="insightsWorkbooks_editInsightsWorkbooks_title"
      />
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
        {({ isSubmitting, dirty, isValid, handleSubmit }) => {
          return (
            <>
              <DialogContent>
                <InsightsWorkbookProfileTab statusOptions={statusOptions} mode={mode} />
              </DialogContent>
              <DialogActions
                defaultButtonDisabled={isSubmitting || !dirty || !isValid}
                defaultButtonOnClick={handleSubmit}
              />
            </>
          );
        }}
      </Formik>
    </DialogContainer>
  );
};

export default InsightsWorkbookFormView;
