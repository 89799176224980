import { Form, Modal } from 'connex-cds';
import devicesConfig from './utils/devicesConfig';
import { useVehicleDevices } from '../../query-hooks/devices';
import { DeviceDrawer } from './components/DeviceDrawer';
import { GMButton } from '../../cds-labs/GMButton';
import AddIcon from '@mui/icons-material/Add';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { AddDevice } from './components/AddDevice';
import { dynamicSort } from '../assurance/utils/dynamicSort';

export const Devices = () => {
  const { entityRef } = useParams();
  const { DETAIL_TYPES, ListAndDetail } = Form;
  const { openModal, closeModal } = Modal.useModalContext();
  const listVehicleDevices = useVehicleDevices();
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    const tmpVehicles = [];
    if (listVehicleDevices?.data && listVehicleDevices?.data.length > 0) {
      // Sorting the commands with a custom function
      const sortedData = listVehicleDevices?.data.sort(dynamicSort('vehicleId'));

      sortedData.forEach(vehicle => {
        tmpVehicles.push({ crn: vehicle.crn, id: vehicle.vehicleId, deviceConfigRefs: vehicle.deviceConfigRefs });
      });
    }

    setVehicles([...tmpVehicles]);
  }, [listVehicleDevices?.data]);

  const openAddDeviceToVehicle = useCallback(() => {
    openModal({
      titleStringId: 'addDeviceModalTitle',
      component: <AddDevice onCancel={closeModal} entityRef={entityRef} vehicles={vehicles} />,
      width: '600px',
    });
  }, [openModal, closeModal, entityRef, vehicles]);

  return (
    <>
      <GMButton
        startIcon={<AddIcon />}
        id="newDeviceBtn"
        type="primary"
        onClick={openAddDeviceToVehicle}
        disabled={listVehicleDevices.isLoading}
      />
      <ListAndDetail
        detailOptions={{ width: '900px' }}
        detailType={DETAIL_TYPES.DRAWER}
        showHeader={false}
        config={devicesConfig}
        listQuery={listVehicleDevices}
        Editor={() => <DeviceDrawer doRefetch={true} />}
      />
    </>
  );
};
