import { useMemo, useState } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import InsightsWorkbookFormView from '../insights-workbook-form-view';
import { useUpdateInsightsWorkbook } from '../../../../query-hooks/insights';

const EditInsightsWorkbookDialog = ({ onClose, itemData, handleOnDelete }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();
  const { mutateAsync: updateInsightsWorkbook } = useUpdateInsightsWorkbook();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    formikBag.setSubmitting(true);
    try {
      const data = {
        ...values,
        id: undefined,
      };
      await updateInsightsWorkbook({ data, crn: itemData.crn });
      notification.success(translateMessage('insightsWorkbooks_notification_insightsWorkbookUpdated'));
      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const getInitialValues = useMemo(() => {
    if (!itemData) return null;
    const { id, name, groupName, reportName, collectionName, icon, toolbar, tooltip, tabs, status } = itemData;

    const editValues = {
      id,
      name,
      toolbar,
      tooltip,
      tabs,
      groupName: groupName || '',
      reportName: reportName || '',
      collectionName: collectionName || '',
      icon: icon || '',
      status: STATUS_OPTIONS.find(item => item.value === status)?.value,
    };

    return editValues;
  }, [itemData]);

  return (
    <InsightsWorkbookFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.EDIT}
      initialValues={getInitialValues}
      onSubmit={handleOnSubmit}
      statusOptions={STATUS_OPTIONS}
      handleOnDelete={handleOnDelete}
    />
  );
};

export default EditInsightsWorkbookDialog;
