import { Core, Form, Modal } from 'connex-cds';
import deviceGroupsConfig from './utils/deviceGroupsConfig';
import { useDeviceGroups } from '../../query-hooks/groups';
import { EditGroup } from './components/EditGroup';
import { useCallback } from 'react';
import { GMButton } from '../../cds-labs/GMButton';
import AddIcon from '@mui/icons-material/Add';
import { CreateGroup } from './components/CreateGroup';
import { useParams } from 'react-router';
import { Divider, Empty } from 'antd';

export const Groups = () => {
  const listDeviceGroups = useDeviceGroups();
  const { entityRef } = useParams();
  const { openModal, closeModal } = Modal.useModalContext();
  const { DETAIL_TYPES, ListAndDetail } = Form;

  const openCreateGroup = useCallback(() => {
    openModal({
      titleStringId: 'deviceGroupsEditor',
      component: <CreateGroup onCancel={closeModal} entityRef={entityRef} refetchData={true} />,
      width: '900px',
    });
  }, [openModal, closeModal, entityRef]);

  return (
    <>
      <GMButton startIcon={<AddIcon />} id="newGroupBtn" type="primary" onClick={openCreateGroup} />
      {(!listDeviceGroups.isLoading && !listDeviceGroups?.data) ||
        (listDeviceGroups?.data?.length <= 0 && (
          <>
            <Divider></Divider>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </>
        ))}
      {!listDeviceGroups.isLoading && listDeviceGroups?.data?.length > 0 && (
        <ListAndDetail
          detailOptions={{ width: '900px' }}
          detailType={DETAIL_TYPES.MODAL}
          showHeader={false}
          config={deviceGroupsConfig}
          listQuery={listDeviceGroups}
          Editor={EditGroup}
        />
      )}
    </>
  );
};
