import { http } from 'connex-cds';

export default {
  getAllVehicleDevices: ({ entityRef }) => http.get({ apiName: 'assurance', path: `/${entityRef}/vehicle-devices` }),
  getOneVehicleDevices: ({ entityRef, crn }) =>
    http.get({ apiName: 'assurance', path: `/${entityRef}/vehicle-devices/${crn}?expand=deviceConfig` }),
  getVehicleExpandedDevicesConfig: ({ entityRef }) =>
    http.get({ apiName: 'assurance', path: `/${entityRef}/vehicles?expand=device-configs` }),
  updateDevice: ({ entityRef, crn, data }) =>
    http.patch({ apiName: 'assurance', path: `/${entityRef}/vehicle-devices/${crn}`, data }),
  postVehicleDevice: ({ entityRef, data }) =>
    http.post({ apiName: 'assurance', path: `/${entityRef}/vehicle-devices`, data }),
  getAllVehicleWithoutDevices: ({ entityRef }) => http.get({ apiName: 'assurance', path: `/${entityRef}/vehicles` }),
};
