import { Form } from 'connex-cds';
import * as Yup from 'yup';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CloseIcon from '@mui/icons-material/Close';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { Chip } from '@mui/material';
import styled from 'styled-components';
import style from './style';
import moment from 'moment';

const Styled = styled.div`
  ${style}
`;

const FAILED_STATUS_MESSAGES = 'FAILED';
const PENDING_STATUS_MESSAGES = 'PENDING';
const SENT_STATUS_MESSAGES = 'SENT';
const COMPLETED_STATUS_MESSAGES = 'COMPLETED';

export default {
  path: 'deviceCommands',
  labelStringId: 'deviceCommands',
  testId: 'deviceCommands',
  keyPath: 'crn',
  validationSchema: Yup.object().shape({
    DeviceMessage: Yup.array(),
    crn: Yup.string().required('required'),
    vehicleRef: Yup.string().required('required'),
    vehicleId: Yup.string().required('required'),
    vehicleDeviceRef: Yup.string().required('required'),
    commandText: Yup.string(),
    addDateTime: Yup.date(),
    sentDateTime: Yup.date(),
    ackDateTime: Yup.date(),
    status: Yup.boolean().required('required'),
  }),

  fields: [
    {
      path: 'vehicleId',
      valuePath: 'vehicleId',
      displayPath: 'label',
      dataType: Form.FIELD_TYPES.string,
      listValues: [],
      listValuePath: 'crn',
      listDisplayPath: 'label',
      labelStringId: 'vehicleId',
      testId: 'vehicleId',
    },
    {
      path: 'commandText',
      valuePath: 'commandText',
      displayPath: 'description',
      dataType: Form.FIELD_TYPES.string,
      listValues: [],
      listValuePath: 'crn',
      listDisplayPath: 'name',
      labelStringId: 'commandText',
      testId: 'commandText',
    },
    {
      path: 'addDateTime',
      valuePath: 'addDateTime',
      displayPath: 'description',
      dataType: Form.FIELD_TYPES.date,
      listValues: [],
      listValuePath: 'crn',
      listDisplayPath: 'name',
      labelStringId: 'addDateTime',
      testId: 'addDateTime',
      formatter: props => {
        return moment(props.row.addDateTime).format('MM-DD-YYYY hh:mm:ss');
      },
    },
    {
      path: 'sentDateTime',
      hidden: true,
      valuePath: 'sentDateTime',
      displayPath: 'description',
      dataType: Form.FIELD_TYPES.date,
      listValues: [],
      listValuePath: 'crn',
      listDisplayPath: 'name',
      labelStringId: 'sentDateTime',
      testId: 'sentDateTime',
      formatter: props => {
        return moment(props.row.sentDateTime).format('MM-DD-YYYY hh:mm:ss');
      },
    },
    {
      path: 'ackDateTime',
      hidden: true,
      valuePath: 'ackDateTime',
      displayPath: 'description',
      dataType: Form.FIELD_TYPES.date,
      listValues: [],
      listValuePath: 'crn',
      listDisplayPath: 'name',
      labelStringId: 'ackDateTime',
      testId: 'ackDateTime',
      formatter: props => {
        return moment(props.row.ackDateTime).format('MM-DD-YYYY hh:mm:ss');
      },
    },
    {
      path: 'status',
      valuePath: 'status',
      dataType: Form.FIELD_TYPES.string,
      listDisplayPath: 'name',
      labelStringId: 'status',
      testId: 'status',
      formatter: props => (
        <div className="deviceMessageStatus">
          <Styled>
            <Chip
              id={`${props.row.crn}`}
              className={`message-status message-status-${props.row.status.toLowerCase()}`}
              icon={
                props.row.status === SENT_STATUS_MESSAGES ? (
                  <CheckIcon className={`message-status-icon`} />
                ) : props.row.status === PENDING_STATUS_MESSAGES ? (
                  <AccessTimeFilledIcon className={`message-status-icon`} />
                ) : props.row.status === FAILED_STATUS_MESSAGES ? (
                  <ErrorIcon className={`message-status-icon`} />
                ) : props.row.status === COMPLETED_STATUS_MESSAGES ? (
                  <DoneAllIcon className={`message-status-icon`} />
                ) : (
                  <CloseIcon className={`message-status-icon`} />
                )
              }
              label={props.row.status}
            ></Chip>
          </Styled>
        </div>
      ),
    },
  ],
};
