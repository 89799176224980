/**
* This file may contain code that utilizes generative AI for code assistance, unit testing and/or entire
functions.
www.commandalkon.com
* The generative model(s) used may be a combination of GitHub CoPilot, OpenAI ChatGPT or others.
*/
import { useCallback, useMemo, useState } from 'react';
import { Localization } from 'connex-cds';

import { getFilteredDataByProperties } from '../commons/search-filter';
import { DIALOG_TYPES } from '../constants';

export const useTableLogic = (useList, getListColumns, filteredPropsArr, addQueryParams = {}) => {
  const [isOnEditDialog, setIsOnEditDialog] = useState(false);
  const translateMessage = Localization.useTranslateMessage();
  const { getNumber } = Localization.useNumber();
  const { userLocale } = Localization.useLocalizationContext();
  const [query, setQuery] = useState('');
  const [currentEntity, setCurrentEntity] = useState(null);
  const [dialog, setDialog] = useState(DIALOG_TYPES.NONE);
  const { data, isLoading, isRefetching, refetch } = useList({ queryParams: { activeOnly: false, ...addQueryParams } });

  const filteredData = useMemo(
    () => getFilteredDataByProperties({ data, query, properties: filteredPropsArr }),
    [data, query, filteredPropsArr]
  );

  const totalRows = data?.length || 0;

  const setDelete = useCallback(
    entity => () => {
      setDialog(DIALOG_TYPES.DELETE);
      setCurrentEntity(entity);
    },
    []
  );

  const entityColumns = useMemo(
    () =>
      getListColumns({
        setDeleteEntity: setDelete,
        translateMessage,
        getNumber,
        userLocale,
      }),
    [getListColumns, setDelete, translateMessage, getNumber, userLocale]
  );

  const resetDialogs = (isDeleteSuccess = false) => {
    if (isOnEditDialog && !isDeleteSuccess) {
      setDialog(DIALOG_TYPES.EDIT);
      setIsOnEditDialog(false);
      return;
    }
    setIsOnEditDialog(false);
    setCurrentEntity(null);
    setDialog(DIALOG_TYPES.NONE);
  };

  const handleOnRowClick = params => {
    setCurrentEntity(params.row);
    setDialog(DIALOG_TYPES.EDIT);
  };

  const filteredDataStatus = useMemo(
    () => ({
      hasFilteredData: filteredData.length > 0,
      isFilteredData: query.length > 0,
      isFilteredDataEmpty: filteredData.length === 0,
    }),
    [filteredData.length, query.length]
  );

  return {
    setQuery,
    currentEntity,
    setCurrentEntity,
    dialog,
    setDialog,
    data,
    isLoading: isLoading || isRefetching,
    refetch,
    filteredData,
    filteredDataStatus,
    entityColumns,
    resetDialogs,
    handleOnRowClick,
    isOnEditDialog,
    setIsOnEditDialog,
    totalRows,
  };
};
