import menuLogo from './assets/menu-logo.svg';

export default {
  menuLogo,
  sidebarVariant: 'secondary',
  sidebarOptions: [
    { id: 'commands', labelStringId: 'commands', path: '/commands', iconName: 'CellWifiOutlined' },
    { id: 'insights', labelStringId: 'insights', path: '/insights', iconName: 'InsertChartOutlined' },
  ],
};
