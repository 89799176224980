import { useMemo } from 'react';
import { Theme } from 'connex-cds';
import { createTheme } from '@mui/material/styles';
import MuiThemeProvider from '@mui/material/styles/ThemeProvider';
import { Global } from '@emotion/react';
import GlobalStyles from './styles';
import { useLocation } from 'react-router-dom';

import getDesignConfig from '../modes/config';

const ThemeProvider = ({ children }) => {
  const { themeName } = Theme.useThemeContext();
  const { pathname } = useLocation();

  const theme = useMemo(() => createTheme(getDesignConfig(themeName)), [themeName]);
  const currentPath = pathname.slice(1).split('/');

  const globalStyles = GlobalStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <Global styles={globalStyles} key={`${currentPath[currentPath.length - 1]}_${globalStyles.name}`} />
      {children}
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
