import { Auth, User } from 'connex-cds';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import menuConfig from '../../menu-config';

const { IFrameAuthenticated } = Auth;

const AuthenticatedRoutes = ({ setMenuConfig }) => {
  const { entityRef } = useParams();
  const UserPermissions = User.usePermissions();

  useEffect(() => {
    let newMenuConfig = { ...menuConfig };
    if (UserPermissions.isPlatformAdmin || UserPermissions.isPlatformSupport) {
      newMenuConfig.sidebarOptions = [
        { id: 'commands', labelStringId: 'commands', path: '/commands', iconName: 'CellWifiOutlined' },
        { id: 'groups', labelStringId: 'groups', path: '/groups', iconName: 'GroupAddOutlined' },
        { id: 'devices', labelStringId: 'devices', path: '/devices', iconName: 'DevicesOutlined' },
        {
          id: 'defaultConfig',
          labelStringId: 'defaultConfig',
          path: '/default-config',
          iconName: 'DisplaySettingsOutlined',
        },
        { id: 'insights', labelStringId: 'insights', path: '/insights', iconName: 'InsertChartOutlined' },
        {
          id: 'insights-settings',
          labelStringId: 'insights-settings',
          path: '/settings-insights',
          iconName: 'SettingsOutlined',
        },
      ];
    }

    setMenuConfig(newMenuConfig);
  }, [UserPermissions?.isPlatformAdmin, UserPermissions?.isPlatformSupport, setMenuConfig]);

  return <IFrameAuthenticated entityRef={entityRef} menuConfig={[]} />;
};

export default AuthenticatedRoutes;
