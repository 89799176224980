import { Chip, TableCell, TableRow } from '@mui/material';
import { useGetDeviceConfig } from '../../../query-hooks/devices';
import { useEffect, useState } from 'react';

export const CustomRow = ({ data, onClick }) => {
  // const device = useGetDeviceConfig(data.deviceConfigRef, data.entityRef);
  const [deviceConfigData, setDeviceConfigData] = useState({});

  useEffect(() => {
    if (data) {
      setDeviceConfigData({ ...data });
    }
  }, [data]);

  return (
    <TableRow
      key={deviceConfigData?.crn}
      onClick={() => onClick(deviceConfigData?.configuration)}
      style={{ cursor: 'pointer' }}
    >
      <TableCell component="th" scope="row">
        {deviceConfigData?.id}
      </TableCell>
      <TableCell>
        {!deviceConfigData?.default && (
          <Chip label="CUSTOM" color="success" variant="outlined" style={{ width: '90px' }} />
        )}
        {deviceConfigData?.default && <Chip label="DEFAULT" color="info" style={{ width: '90px' }} />}
      </TableCell>
      <TableCell>{deviceConfigData?.deviceType}</TableCell>
    </TableRow>
  );
};
