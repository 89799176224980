import { css } from '@emotion/react';

export const ICON_SIZES = {
  tiny: '16px',
  small: '24px',
  medium: '32px',
  large: '48px',
  xlarge: '64px',
  xxlarge: '96px',
};

const IconStyles = ({ theme, size }) => css`
  font-size: ${ICON_SIZES[size] || ICON_SIZES.small};
`;

export default IconStyles;
