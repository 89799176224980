import React from 'react';
import { Localization } from 'connex-cds';
import { getIn } from 'formik';
import { omit } from 'lodash';
import { any, bool, func, shape, string } from 'prop-types';

import { TextField as InputTextField } from '@cai/material';

import { withFormikField } from '../../hoc';

const InputTextBase = ({
  name,
  value,
  disabled,
  testId,
  onChange,
  isSubmitting,
  labelStringId,
  type,
  errors,
  getInputProps,
  innerRef,
  setFieldValue,
  touched,
  token = labelStringId,
  ...otherProps
}) => {
  const translateMessage = Localization.useTranslateMessage();

  const hasError = !!getIn(errors, name) && getIn(touched, name);
  const errorText = hasError ? getIn(errors, name) : '';

  return (
    <InputTextField
      name={name}
      label={translateMessage(token)}
      token={token}
      data-testid={testId}
      value={value}
      ref={innerRef}
      disabled={disabled || isSubmitting}
      type={type}
      error={hasError}
      helperText={translateMessage(errorText)}
      onChange={e => {
        onChange(name, e);
      }}
      InputProps={getInputProps({ name, setFieldValue })}
      inputProps={{ autoComplete: 'off' }}
      {...omit(otherProps, ['setFieldTouched', 'setFieldValue'])}
    />
  );
};

InputTextBase.propTypes = {
  disabled: bool,
  errors: shape({}).isRequired,
  getInputProps: func,
  innerRef: shape({}),
  isSubmitting: bool,
  labelStringId: string,
  token: string,
  name: string.isRequired,
  onChange: func.isRequired,
  setFieldValue: func.isRequired,
  testId: string,
  touched: shape({}).isRequired,
  type: string,
  value: any.isRequired,
};

InputTextBase.defaultProps = {
  disabled: false,
  getInputProps: () => null,
  isSubmitting: false,
  type: 'text',
  value: '',
};

export default withFormikField(InputTextBase);
