import React from 'react';
import { func, shape } from 'prop-types';

import { CaiThemeProvider } from '../providers';

const withFormikField = InputComponent => {
  const EnhancedInputComponent = ({
    field: { name, value, onBlur },
    form: { setFieldValue, setFieldTouched, errors, isSubmitting, touched },
    customOnChange,
    ...otherProps
  }) => {
    return (
      <CaiThemeProvider>
        <InputComponent
          {...otherProps}
          name={name}
          onChange={customOnChange || setFieldValue}
          onBlur={e => {
            setFieldTouched(name);
            onBlur(e);
          }}
          setFieldTouched={setFieldTouched}
          errors={errors}
          value={value}
          isSubmitting={isSubmitting}
          setFieldValue={setFieldValue}
          touched={touched}
        />
      </CaiThemeProvider>
    );
  };

  EnhancedInputComponent.propTypes = {
    customOnChange: func,
    field: shape({}).isRequired,
    form: shape({}).isRequired,
  };

  EnhancedInputComponent.defaultProps = {
    customOnChange: null,
  };

  return EnhancedInputComponent;
};

export default withFormikField;
