import { Core, Form, Modal } from 'connex-cds';
import deviceCommandsconfig from './utils/deviceCommandsconfig';
import { useDeviceCommands } from '../../query-hooks/assurance';
import { SentMessageModal } from './components/SentMessageModal';
import { useCallback, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Divider, Empty, Result } from 'antd';
import { GMButton } from '../../cds-labs/GMButton';
import { NewDeviceMessageModal } from './components/NewDeviceMessageModal';
import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';

export const Assurance = () => {
  const listDeviceCommands = useDeviceCommands();
  const { DETAIL_TYPES, ListAndDetail } = Form;
  const { openModal, closeModal } = Modal.useModalContext();
  const { entityRef } = useParams();
  const [refetchList, setRefetchList] = useState(false);

  useEffect(() => {
    if (refetchList) {
      listDeviceCommands.refetch(true);
      setRefetchList(false);
    }
  }, [listDeviceCommands, refetchList]);

  const openSentCommandModal = useCallback(() => {
    openModal({
      titleStringId: 'deviceCommandsEditor',
      component: <SentMessageModal onCancel={closeModal} />,
    });
  }, [openModal, closeModal]);

  const openNewMessageCommandModal = useCallback(() => {
    openModal({
      component: <NewDeviceMessageModal entityRef={entityRef} refetchData={true} setRefetchList={setRefetchList} />,
      width: '900px',
    });
  }, [entityRef, openModal]);

  return (
    <Core.Spinner spin={listDeviceCommands.isLoading || listDeviceCommands.isRefetching}>
      <GMButton startIcon={<AddIcon />} id="newMessageCommandBtn" type="primary" onClick={openNewMessageCommandModal} />
      {!listDeviceCommands.isLoading && listDeviceCommands.isError && (
        <>
          <Divider></Divider>
          <Result
            status="error"
            title={<FormattedMessage id={'deviceCommandsErrorTitle'} defaultMessage={'Error'} />}
            subTitle={<FormattedMessage id={'deviceCommandsErrorMessage'} defaultMessage={'Error has occurred'} />}
          />
        </>
      )}
      {(!listDeviceCommands.isLoading && !listDeviceCommands?.data) ||
        (listDeviceCommands?.data?.length <= 0 && (
          <>
            <Divider></Divider>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </>
        ))}
      {listDeviceCommands && listDeviceCommands?.data?.length > 0 && (
        <ListAndDetail
          detailOptions={{ width: '75vh' }}
          detailType={DETAIL_TYPES.MODAL}
          showHeader={false}
          config={deviceCommandsconfig}
          listQuery={listDeviceCommands}
          onClick={openSentCommandModal}
          Editor={SentMessageModal}
        />
      )}
    </Core.Spinner>
  );
};
