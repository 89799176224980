import { useEffect, useState } from 'react';
import { useSendDeviceMessage } from '../../../../query-hooks/assurance';
import { Core } from 'connex-cds';
import { Box, Icon, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

export const StepFour = ({ entityRef, command, trucks }) => {
  const { mutate, isIdle, isSuccess, isError } = useSendDeviceMessage();
  const [commandSent, setCommandSent] = useState(false);

  useEffect(() => {
    if (!commandSent) {
      for (const truck of trucks) {
        mutate({
          entityRef: entityRef,
          data: {
            vehicleRef: truck.vehicleRef,
            vehicleId: truck.vehicleId,
            vehicleDeviceRef: truck.vehicleRef,
            commandText: command.command,
          },
        });
      }

      setCommandSent(true);
    }
  }, [command, commandSent, entityRef, trucks]);

  return (
    <>
      <Core.Spinner spin={isIdle || !commandSent}>
        {commandSent && isError && (
          <Box className="command-result">
            <Typography variant="h5">
              <Icon color="error" sx={{ fontSize: 40 }} style={{ verticalAlign: 'middle', marginRight: '20px' }}>
                error
              </Icon>
              <FormattedMessage id={'sendCommandError'} defaultMessage={'sendCommandError'} />
            </Typography>
            <Typography variant="h6" style={{ margin: '10px auto' }}>
              <FormattedMessage id={'contactAdministrator'} defaultMessage={'contactAdministrator'} />
            </Typography>
          </Box>
        )}
        {commandSent && isSuccess && (
          <Box className="command-result">
            <Typography variant="h5">
              <Icon color="success" sx={{ fontSize: 40 }} style={{ verticalAlign: 'middle', marginRight: '20px' }}>
                check
              </Icon>
              <FormattedMessage id={'commandSent'} defaultMessage={'commandSent'} />
            </Typography>
            <Typography variant="h6" style={{ margin: '10px auto' }}>
              {command.id}
            </Typography>
          </Box>
        )}
      </Core.Spinner>
    </>
  );
};
