import React from 'react';
import { array, bool, func, node, number } from 'prop-types';

import Button from '../../button';

import { DialogActionsStyled } from './style';

const DialogActions = ({
  showDefaultActions,
  defaultButtonDisabled,
  defaultButtonOnClick,
  goToStep,
  tabs,
  currentTab,
  lefSide,
  rightSide,
}) => {
  return (
    <DialogActionsStyled>
      {showDefaultActions ? (
        <>
          <div className="first-action-column">
            {tabs.length > 1 && currentTab ? (
              <Button token="general_dialogAction_back" onClick={goToStep(currentTab - 1)} className="step-control" />
            ) : null}
          </div>
          <div className="second-action-column">
            {tabs.length > 1 && currentTab < tabs.length - 1 ? (
              <Button token="general_dialogAction_next" onClick={goToStep(currentTab + 1)} className="step-control" />
            ) : null}
            <Button
              token="general_dialogAction_save"
              type="submit"
              variant="contained"
              disabled={defaultButtonDisabled}
              onClick={defaultButtonOnClick}
            />
          </div>
        </>
      ) : (
        <>
          <div className="first-action-column">{lefSide}</div>
          <div className="second-action-column">{rightSide}</div>
        </>
      )}
    </DialogActionsStyled>
  );
};

DialogActions.propTypes = {
  showDefaultActions: bool,
  defaultButtonDisabled: bool,
  defaultButtonOnClick: func,
  goToStep: func,
  tabs: array,
  currentTab: number,
  lefSide: node,
  rightSide: node,
};

DialogActions.defaultProps = {
  showDefaultActions: true,
  defaultButtonDisabled: true,
  defaultButtonOnClick: null,
  goToStep: null,
  tabs: [],
  currentTab: null,
  lefSide: null,
  rightSide: null,
};

export default DialogActions;
