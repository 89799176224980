import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { DialogActions } from '@mui/material';

export const DialogActionsStyled = styled(DialogActions)`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px 20px;

    .first-action-column {
      display: flex;
      gap: 22px;
    }

    .second-action-column {
      display: flex;
      gap: 22px;
    }

    button {
      font-size: 16px;
      padding: 14px 24px;
      line-height: 20px;
      border-radius: 100px;

      .step-control {
        ${theme.validateMode({ dark: `color: ${theme.colorsCA.blue[80]};` })}
      }
    }
  `}
`;
