import { Core, Form } from 'connex-cds';
import { Divider, Empty } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useGetDefaultDeviceConfig } from '../../query-hooks/devices';
import config from './utils/config';
import { Button, Stack } from '@mui/material';
import styled from 'styled-components';
import style from './utils/style';

const Styled = styled.div`
  ${style}
`;

export const DefaultConfig = () => {
  const listDefaultDevicesConfig = useGetDefaultDeviceConfig();
  const { DETAIL_TYPES, ListAndDetail } = Form;

  return (
    <Core.Spinner spin={listDefaultDevicesConfig?.isLoading || listDefaultDevicesConfig?.isRefetching}>
      <Styled>
        <Stack spacing={2} direction="row" justifyContent="flex-end">
          <Button variant="contained" className="config-default" key="btn1">
            <FormattedMessage id={'entityDefault'} defaultMessage={'entityDefault'} />
          </Button>
          <Button variant="contained" className="user-config-custom" key="btn2">
            <FormattedMessage id={'entityDefaultCustom'} defaultMessage={'entityDefaultCustom'} />
          </Button>
        </Stack>
      </Styled>

      {(!listDefaultDevicesConfig?.isLoading && !listDefaultDevicesConfig?.data) ||
        (listDefaultDevicesConfig?.data?.length <= 0 && (
          <>
            <Divider></Divider>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </>
        ))}
      {listDefaultDevicesConfig && listDefaultDevicesConfig?.data?.length > 0 && (
        <ListAndDetail
          detailOptions={{ width: '75vh' }}
          detailType={DETAIL_TYPES.MODAL}
          showHeader={false}
          config={config}
          listQuery={listDefaultDevicesConfig}
          onPick={() => {}}
        />
      )}
    </Core.Spinner>
  );
};
