import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 68px 0;
  gap: 35px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  > svg:first-of-type {
    max-width: 306.613px;
    max-height: 162.747px;
    width: 100%;
    height: 100%;
  }
`;

export const NoDataContent = styled.div`
  ${({ isSearching }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 19px;
    width: ${isSearching ? '336px' : '236px'};
  `}
`;

export const NoDataHeader = styled.div`
  text-align: center;
  font-family: 'Work Sans';
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
`;

export const NoDataText = styled.div`
  text-align: center;
  font-family: 'Work Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const NoDataWrapper = styled.div`
  position: relative;
  top: 40%;
`;
