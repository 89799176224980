import { Form } from 'connex-cds';
import * as Yup from 'yup';
import { Box, Chip } from '@mui/material';

export default {
  path: 'deviceGroups',
  labelStringId: 'deviceGroups',
  testId: 'deviceGroups',
  keyPath: 'crn',
  validationSchema: Yup.object().shape({
    crn: Yup.string().required('required'),
    id: Yup.string().required('required'),
    vehicleDeviceRefs: Yup.number().required('required'),
  }),

  fields: [
    {
      path: 'id',
      valuePath: 'id',
      displayPath: 'label',
      dataType: Form.FIELD_TYPES.string,
      listValues: [],
      listValuePath: 'crn',
      listDisplayPath: 'label',
      labelStringId: 'groupIdName',
      testId: 'id',
    },
    {
      path: 'vehicleDeviceRefs',
      valuePath: 'vehicleDeviceRefs',
      dataType: Form.FIELD_TYPES.number,
      listDisplayPath: 'quantity',
      labelStringId: 'vehicleDeviceRefs',
      testId: 'vehicleDeviceRefs',
      formatter: props => (
        <Box style={{ textAlign: 'center' }}>
          <Chip
            style={{ width: '60px', height: '25px', cursor: 'pointer' }}
            color="success"
            variant="outlined"
            id={`${props.row.crn}`}
            label={props.row.vehicleDeviceRefs.length}
          ></Chip>
        </Box>
      ),
    },
  ],
};
