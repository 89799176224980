import { Route, Routes } from 'react-router-dom';
import { InsightsList } from './InsightsList';
import { InsightsViewer } from './InsightsViewer';

export const Insights = () => {
  return (
    <Routes>
      <Route path="/" element={<InsightsList />} />
      <Route path="/:insightsWorkbookRef" element={<InsightsViewer />} />
    </Routes>
  );
};
