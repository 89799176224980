import { useMemo } from 'react';
import { Localization } from 'connex-cds';
import { getIn } from 'formik';
import { omit } from 'lodash';
import { array, bool, func, shape, string } from 'prop-types';

import { SelectField } from '@cai/material';

import { withFormikField } from '../../hoc';

const Select = ({
  name,
  value,
  disabled,
  required,
  disableClearable,
  testId,
  onChange,
  isSubmitting,
  labelStringId,
  errors,
  options,
  setFieldTouched,
  touched,
  sortOptionsByTranslationKey,
  sortOptionsByLabel,
  ...otherProps
}) => {
  const translateMessage = Localization.useTranslateMessage();

  const hasError = !!getIn(errors, name) && getIn(touched, name);
  const errorText = hasError ? errors[name] : '';

  const finalOptions = useMemo(() => {
    if (!sortOptionsByTranslationKey && !sortOptionsByLabel) {
      return options;
    } else if (sortOptionsByTranslationKey) {
      return options
        ?.map(option => ({ ...option, label: translateMessage(option?.translationKey) }))
        ?.sort((a, b) => a?.label?.localeCompare(b?.label));
    } else if (sortOptionsByLabel) {
      return options?.sort((a, b) => a?.label?.localeCompare(b?.label));
    }
  }, [options, sortOptionsByTranslationKey, sortOptionsByLabel, translateMessage]);

  return (
    <SelectField
      name={name}
      value={value}
      data-testid={testId}
      error={hasError}
      helperText={translateMessage(errorText)}
      disabled={disabled || isSubmitting}
      required={required}
      onChange={e => {
        onChange(name, e);
      }}
      token={labelStringId}
      options={finalOptions}
      disableClearable={required || disableClearable}
      {...omit(otherProps, ['setFieldTouched', 'setFieldValue'])}
    />
  );
};

Select.propTypes = {
  errors: shape({}).isRequired,
  disabled: bool,
  required: bool,
  disableClearable: bool,
  isSubmitting: bool,
  labelStringId: string.isRequired,
  name: string.isRequired,
  onChange: func.isRequired,
  options: array.isRequired,
  testId: string,
  value: string.isRequired,
  setFieldTouched: func.isRequired,
  touched: shape({}).isRequired,
  sortOptionsByTranslationKey: bool,
  sortOptionsByLabel: bool,
};

Select.defaultProps = {
  disabled: false,
  required: false,
  disableClearable: false,
  options: [],
  isSubmitting: false,
  value: '',
  sortOptionsByTranslationKey: false,
  sortOptionsByLabel: false,
};

export default withFormikField(Select);
