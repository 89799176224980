import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { Box, Typography } from '@mui/material';
import { GMButton } from './GMButton';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { FormattedMessage } from 'react-intl';
import CircularProgress from '@mui/material/CircularProgress';

export function GMConfirmDialog({ onClose, open, title, content, okBtnText, onClick, loading }) {
  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog sx={{ '& .MuiDialog-paper': { width: '90%', maxHeight: 435 } }} maxWidth="xs" open={open}>
      <DialogTitle sx={{ fontSize: '1rem' }}>
        <WarningAmberIcon color="warning" style={{ verticalAlign: 'middle' }} sx={{ fontSize: 25 }} />
        <FormattedMessage id={title} defaultMessage={'defaultMessage'} />
      </DialogTitle>
      <DialogContent dividers>
        {loading && (
          <Box style={{ textAlign: 'center', margin: '30px auto' }}>
            <CircularProgress />
          </Box>
        )}
        {!loading && (
          <Box style={{ height: '70px' }}>
            <Typography>
              <FormattedMessage id={content} defaultMessage={'defaultMessage'} />
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <GMButton autoFocus onClick={handleCancel} id="cancelBtn" type="secondary" disabled={loading} />
        <GMButton onClick={onClick} type="error" id={okBtnText} variant="outlined" disabled={loading} />
      </DialogActions>
    </Dialog>
  );
}
