import { css } from '@emotion/react';

const GlobalStyles = () => css`
  .main-container .content > *:not(header) {
    margin-left: 10px;
    margin-right: 20px;
  }
`;

export default GlobalStyles;
