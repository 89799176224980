import { Card, CardActionArea, CardContent, Stack, ThemeProvider, Typography } from '@mui/material';
import { theme } from './theme';
import { FormattedMessage } from 'react-intl';
import Icon from '@mui/material/Icon';

export const GMSelectedCard = ({
  style,
  iconColor = 'primary',
  iconStyle = null,
  icon = null,
  onClick,
  title = false,
  translateTitle = false,
  titleId = null,
  translateContent = false,
  contentId = null,
  defaultMessage,
  disabled = false,
  className = null,
  selected = false,
  mutedText = null,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <Card className={disabled ? `${className}-disabled` : className} onClick={onClick} disabled={disabled}>
        <CardActionArea>
          <CardContent>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0}>
              {title && translateTitle && (
                <Typography gutterBottom variant="h6">
                  <FormattedMessage id={titleId} defaultMessage={defaultMessage} />
                </Typography>
              )}
              {title && (
                <Typography gutterBottom variant="body2">
                  {titleId}
                </Typography>
              )}
              {selected && (
                <Icon color="success" sx={{ fontSize: 40 }}>
                  check
                </Icon>
              )}
              {contentId && translateContent && (
                <Typography variant="body2" color="text.secondary">
                  <FormattedMessage id={contentId} defaultMessage={defaultMessage} />
                </Typography>
              )}
              {contentId && (
                <Typography variant="h6" color="text.secondary">
                  {contentId}
                </Typography>
              )}
              {icon && (
                <Icon color={disabled ? 'secondary' : iconColor} style={iconStyle}>
                  {icon}
                </Icon>
              )}
            </Stack>
            {mutedText && (
              <Typography variant="body2" color="text.secondary">
                {mutedText}
              </Typography>
            )}
          </CardContent>
        </CardActionArea>
      </Card>
    </ThemeProvider>
  );
};
