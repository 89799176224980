import { css } from '@emotion/react';
import styled from '@emotion/styled';
import MuiDialogTitle from '@mui/material/DialogTitle';

const styles = ({ theme }) => css`
  position: relative;
  display: flex;
  font-size: 30px;
  line-height: 20px;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  gap: 10px;

  .buttons-container {
    position: absolute;
    top: 20px;
    right: 22px;
    display: flex;
    gap: 15px;
  }

  .icon-button {
    padding: 5px;
    border: 1px solid ${theme.validateMode({ light: '#cac4d0', dark: '#49454F' })};
    box-shadow: ${theme.validateMode({
      light: '0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3)',
      dark: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    })};
  }
`;

export const DialogTitleStyled = styled(MuiDialogTitle)`
  ${styles}
`;
