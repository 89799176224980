import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { Devices, VehicleDevices } from '../api';

/**
 * Function for Get All the devices
 * @author Juan David Reina
 */
export const useVehicleDevices = () => {
  const { entityRef } = useParams();

  if (!entityRef) {
    console.trace();
    return undefined;
  }

  const query = useQuery({
    queryKey: ['listVehicleDevices', entityRef],
    queryFn: () => VehicleDevices.getAllVehicleDevices({ entityRef }),
    staleTime: Infinity,
    retry: 0,
  });

  return query;
};

/**
 * Function for Get All the devices
 * @author Juan David Reina
 */
export const useVehicleWithoutDevices = entityRef => {
  if (!entityRef) {
    console.trace();
    return undefined;
  }

  const query = useQuery({
    queryKey: ['listVehicleWithoutDevices', entityRef],
    queryFn: () => VehicleDevices.getAllVehicleWithoutDevices({ entityRef }),
    staleTime: Infinity,
    retry: 0,
  });

  return query;
};

/**
 * Function for Get the devices
 * @author Juan David Reina
 */
export const useGetOneVehicleDevices = crn => {
  const { entityRef } = useParams();

  if (!entityRef) {
    console.trace();
    return undefined;
  }

  const query = useQuery({
    queryKey: ['getOneVehicleDevices', entityRef, crn],
    queryFn: () => VehicleDevices.getOneVehicleDevices({ entityRef, crn }),
    staleTime: 0,
    retry: 0,
  });

  return query;
};

/**
 * Function for Get one devices
 * @author Juan David Reina
 */
export const useGetDeviceConfig = (crn, entityRef) => {
  const usrEntityRef = useParams().entityRef;

  if (!entityRef) {
    console.trace();
    return undefined;
  }

  // Return false if the incoming entityRef are diferent that the curent user
  if (usrEntityRef !== entityRef) {
    console.trace();
    return undefined;
  }

  const query = useQuery({
    queryKey: ['getDevice', entityRef, crn],
    queryFn: () => Devices.getDeviceConfig({ entityRef, crn }),
    staleTime: Infinity,
    retry: 0,
  });

  return query;
};

/**
 * Function for Get the devices config default by entity
 * @author Juan David Reina
 */
export const useGetDefaultDeviceConfig = () => {
  const { entityRef } = useParams();

  if (!entityRef) {
    console.trace();
    return undefined;
  }

  const query = useQuery({
    queryKey: ['getDefaultConfig', entityRef],
    queryFn: () => Devices.getDefaultDeviceConfig({ entityRef }),
    staleTime: Infinity,
    retry: 0,
  });

  return query;
};

/**
 * Function for Add one device to vehicle
 * @author Juan David Reina
 */
export const useAddDeviceToVehicle = entityRef => {
  const queryClient = useQueryClient();
  const queryKey = ['addDeviceToVehicle', entityRef];

  const mutation = useMutation({
    mutationFn: data => {
      VehicleDevices.updateDevice({ ...data });
    },
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ['listVehicleDevices'] });
      }, 1000);
    },
  });

  return mutation;
};

/**
 * Function for Add new device config
 * @author Juan David Reina
 */
export const useAddNewDeviceConfig = (crn, entityRef) => {
  const queryClient = useQueryClient();
  const queryKey = ['addNewDeviceConfig', entityRef, crn];

  const mutation = useMutation({
    mutationFn: data => {
      Devices.postDeviceConfig({ ...data });
    },
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ['listVehicleDevices', 'getDevice', 'getOneVehicleDevices'] });
      }, 500);
    },
    onSuccess: () => {
      queryClient.invalidateQueries('listVehicleDevices');
      queryClient.invalidateQueries('getDevice');
      queryClient.invalidateQueries('getOneVehicleDevices');
    },
  });

  return mutation;
};

/**
 * Function for Add new vehicle device
 * @author Juan David Reina
 */
export const useAddNewVehicleDevice = entityRef => {
  const queryClient = useQueryClient();
  const queryKey = ['addNewVehicleDevice', entityRef];

  const mutation = useMutation({
    mutationFn: data => {
      VehicleDevices.postVehicleDevice({ ...data });
    },
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ['listVehicleDevices', 'getDevice', 'getOneVehicleDevices'] });
      }, 500);
    },
    onSuccess: () => {
      queryClient.invalidateQueries('listVehicleDevices');
      queryClient.invalidateQueries('getDevice');
      queryClient.invalidateQueries('getOneVehicleDevices');
    },
  });

  return mutation;
};

/**
 * Function for update a device config
 * @author Juan David Reina
 */
export const useUpdateDeviceConfig = (crn, entityRef) => {
  const queryClient = useQueryClient();
  const queryKey = ['updateDeviceConfig', entityRef, crn];

  const mutation = useMutation({
    mutationFn: data => {
      Devices.patchDeviceConfig({ ...data });
    },
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ['listVehicleDevices', 'getDevice', 'getOneVehicleDevices'] });
      }, 500);
    },
    onSuccess: () => {
      queryClient.invalidateQueries('listVehicleDevices');
      queryClient.invalidateQueries('getDevice');
      queryClient.invalidateQueries('getOneVehicleDevices');
    },
  });

  return mutation;
};
