import { css } from 'styled-components';

export default css`
  // MESSAGE STATUS
  .message-status {
    color: #ffffff;
    height: 25px;
    margin-top: 2px;
    vertical-align: inherit;
  }

  .message-status .message-status-icon {
    color: #ffffff;
    font-size: 1.2rem;
    height: auto;
    margin: 2px 5px;
  }

  .message-status span {
    padding-left: 5px;
  }

  .message-status-pending {
    background-color: #ff9800 !important;
  }

  .message-status-sent {
    background-color: #388e3c;
  }

  .message-status-failed {
    background-color: #b40404;
  }

  .message-status-completed {
    background-color: #2962ff;
  }

  .message-status-cancelled {
    background-color: #546e7a;
  }

  // NEW MSG FORM
  .new-message-form-modal {
    border: none;
    margin: -24px;

    > .ant-card-head {
      text-align: center;
    }

    > .ant-card-body {
      max-height: 400px;
      overflow-y: auto;
    }

    > .ant-card-body .command-card-container {
      column-gap: 15px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 10px;
      row-gap: 10px;
    }

    > .ant-card-body .command-card-container .command-card button {
      height: 100%;
    }
    > .ant-card-body .command-card-container .command-card button div {
      padding: 10px;
    }

    > .ant-card-body .command-card-container .command-card-disabled button {
      height: 100%;
    }

    > .ant-card-body .command-card-container .command-card-disabled {
      // pointer-events: none;
      opacity: 0.5;
    }

    > .ant-card-body .command-result {
      margin: 100px auto;
      text-align: center;
    }
  }
`;
