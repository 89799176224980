import { Theme } from 'connex-cds';

import { Theme as CaiMaterialTheme } from '@cai/material';

const CaiThemeProvider = ({ children }) => {
  const { themeName } = Theme.useThemeContext();
  return <CaiMaterialTheme.ThemeProvider themeName={themeName}>{children}</CaiMaterialTheme.ThemeProvider>;
};

export default CaiThemeProvider;
