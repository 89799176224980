import React, { useState } from 'react';
import classNames from 'classnames';
import { bool, func, node, string } from 'prop-types';

import Button from '../button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { ReactComponent as CALogo } from '../../assets/icons/CA-logo.svg';
import DialogTitle from '../custom-dialog/dialog-title';
import { DialogStyled } from './styles';

const ConfirmationDialog = ({ children, open, onClose, onConfirm, confirmText, className, color }) => {
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleConfirm = async () => {
    setButtonDisabled(true);
    await onConfirm();
    onClose(true);
    setButtonDisabled(false);
  };

  const handleCloseDialog = (e, reason) => {
    if (reason === 'escapeKeyDown') {
      onClose();
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <DialogStyled
      className={classNames('confirmation-dialog', className)}
      open={open}
      maxWidth="md"
      fullWidth
      onClose={handleCloseDialog}
    >
      <DialogTitle className="dialog-title" onClose={handleClose}>
        <CALogo className="ca-logo" />
      </DialogTitle>
      <DialogContent dividers className="dialog-content">
        {children}
      </DialogContent>
      <DialogActions className="dialog-actions">
        <Button
          variant="contained"
          token={confirmText ?? 'general_confirm'}
          className={classNames('confirm-button', `confirm-button-${color}`)}
          onClick={handleConfirm}
          disabled={buttonDisabled}
        />
      </DialogActions>
    </DialogStyled>
  );
};

ConfirmationDialog.propTypes = {
  children: node.isRequired,
  className: string,
  color: string,
  confirmText: string,
  onClose: func.isRequired,
  onConfirm: func.isRequired,
  open: bool,
};

ConfirmationDialog.defaultProps = {
  className: '',
  color: 'default',
  confirmText: '',
  open: false,
};

export default ConfirmationDialog;
