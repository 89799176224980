import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import { theme } from './theme';
import { Box, ThemeProvider } from '@mui/material';
import styled from 'styled-components';
import style from './theme/style';

const Styled = styled.div`
  ${style}
`;

export const GMButton = ({
  variant = 'contained',
  type,
  style,
  startIcon = null,
  endIcon = null,
  id = 'null',
  onClick,
  defaultMessage,
  disabled = false,
}) => {
  return (
    <Styled>
      <Box className="gm-button-space">
        <ThemeProvider theme={theme}>
          <Button
            variant={variant}
            style={style}
            onClick={onClick}
            className={`gm-button gm-button-${type}`}
            startIcon={startIcon}
            endIcon={endIcon}
            color={type}
            disabled={disabled}
          >
            <FormattedMessage id={id} defaultMessage={defaultMessage} />
          </Button>
        </ThemeProvider>
      </Box>
    </Styled>
  );
};
