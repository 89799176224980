import { Core } from 'connex-cds';
import { useListDeviceCommands } from '../../../../query-hooks/assurance';
import { useEffect, useState } from 'react';
import { GMSelectedCard } from '../../../../cds-labs/GMSelectedCard';
import { Empty, Layout, Result } from 'antd';
import { FormattedMessage } from 'react-intl';
import { dynamicSort } from '../../utils/dynamicSort';

export const StepOne = ({ entityRef, setNextBtnDisabled, getData = null, setNewCommand }) => {
  const { isLoading, isError, data, refetch, isFetching } = useListDeviceCommands(entityRef);
  const [commandsList, setCommandsList] = useState([]);
  const [selectedCommand, setSelectedCommand] = useState(null);
  const [icon, setIcon] = useState('add_circle');
  const iconStyle = {
    fontSize: '50px',
  };

  useEffect(() => {
    if (getData) {
      refetch();
    }
  }, [getData, refetch]);

  useEffect(() => {
    if (data && data.length > 0) {
      data.forEach(command => {
        command.icon = icon;
      });

      // Sorting the commands with a custom function
      const sortedData = data.sort(dynamicSort('id'));

      setCommandsList(sortedData);
    }
  }, [data, icon]);

  const handleSelectedCommand = commandSelected => {
    setSelectedCommand(commandSelected);

    // Deselect command
    if (selectedCommand && selectedCommand?.crn === commandSelected.crn) {
      commandsList.forEach(command => {
        setIcon('add_circle');
        command.disabled = false;
        command.icon = icon;
        command.iconColor = 'primary';
        command.selected = false;
      });

      setSelectedCommand(null);
      setNextBtnDisabled(true);
      return setCommandsList([...commandsList]);
    }

    // Select a new command
    commandsList.forEach(async command => {
      if (command.crn === commandSelected.crn) {
        command.icon = 'cancel';
        command.disabled = false;
        command.selected = true;
        command.iconColor = 'error';
        setNextBtnDisabled(false);
      } else {
        command.disabled = true;
        command.icon = 'add_circle';
        command.selected = false;
        command.iconColor = 'primary';
      }
    });

    // Set the father component data with the selected command
    setNewCommand({ command: commandSelected?.text, id: commandSelected?.id });

    return setCommandsList([...commandsList]);
  };

  return (
    <Core.Spinner spin={isLoading || isFetching}>
      {!isLoading && isError && (
        <Result
          status="error"
          title={<FormattedMessage id={'ErrorMessageTitle'} defaultMessage={'Error'} />}
          subTitle={<FormattedMessage id={'commandsErrorMessage'} defaultMessage={'Error has occurred'} />}
        />
      )}
      {!isLoading && commandsList && commandsList.length <= 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      {!isLoading && commandsList && (
        <Layout className="command-card-container">
          {commandsList &&
            commandsList.map(command => {
              return (
                <GMSelectedCard
                  key={command.crn}
                  contentId={command.id}
                  className="command-card"
                  icon={command.icon}
                  iconStyle={iconStyle}
                  onClick={() => handleSelectedCommand(command)}
                  disabled={command.disabled}
                  iconColor={command.iconColor}
                  selected={command.selected}
                />
              );
            })}
        </Layout>
      )}
    </Core.Spinner>
  );
};
