import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Dialog from '@mui/material/Dialog';

export const DialogStyled = styled(Dialog)`
  ${({ theme }) => css`
    & > * {
      font-family: Work Sans;
    }

    .MuiDialog-paper {
      max-height: 603px;
      height: 100%;
    }

    .dialog-title {
      .ca-logo {
        height: 37px;
      }
    }

    .dialog-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      ${theme.validateMode({ dark: 'border-top: 1px solid #938F99;' })}
      ${theme.validateMode({ dark: 'border-bottom: 1px solid #938F99;' })}

    .delete-title {
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 20px;
      }

      .delete-description {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px;
        max-width: 33ch;
        text-align: center;
        margin-bottom: 0;

        strong {
          word-break: break-word;
        }
      }
    }

    .dialog-actions {
      display: flex;
      justify-content: end;
      padding: 16px 20px 20px;

      .confirm-button-delete {
        background-color: ${theme.colorsCA.red[50]};
        color: ${theme.colorsCA.neutral[100]};
        border-radius: 100px;
      }

      .Mui-disabled {
        color: ${theme.validateMode({ light: 'rgba(0, 0, 0, 0.26)', dark: 'rgba(255, 255, 255, 0.3)' })};
        background-color: ${theme.validateMode({ light: 'rgba(0, 0, 0, 0.12)', dark: 'rgba(255, 255, 255, 0.12)' })};
        box-shadow: none;
        pointer-events: none;
        cursor: default;
      }
    }
  `}
`;
