import { Localization } from 'connex-cds';
import { func, node, string } from 'prop-types';

import IconButton from '@mui/material/IconButton';

import { DIALOG_TYPES } from '../../../constants';
import { CustomIcon } from '../..';
import { DialogTitleStyled } from './styles';

const HEADER_ICON_BY_MODE = { add: 'addOutlinedIcon', edit: 'editOutlinedIcon' };

const DialogTitle = ({ onDelete, children, onClose, mode, stringIdToAdd, stringIdToEdit }) => {
  const renderButtons = () => (
    <div className="buttons-container">
      {onDelete && (
        <IconButton className="icon-button" variant="outlined" onClick={onDelete}>
          <CustomIcon iconName="deleteIcon" />
        </IconButton>
      )}
      {onClose && (
        <IconButton className="icon-button" variant="outlined" onClick={onClose}>
          <CustomIcon iconName="closeIcon" />
        </IconButton>
      )}
    </div>
  );

  return (
    <DialogTitleStyled>
      {children || (
        <>
          <CustomIcon iconName={HEADER_ICON_BY_MODE[mode]} size={'medium'} />
          <Localization.Translate stringId={mode === DIALOG_TYPES.ADD ? stringIdToAdd : stringIdToEdit} />
        </>
      )}
      {(onDelete || onClose) && renderButtons()}
    </DialogTitleStyled>
  );
};

DialogTitle.propTypes = {
  children: node,
  onClose: func,
  onDelete: func,
  mode: string,
  stringIdToAdd: string,
  stringIdToEdit: string,
};

DialogTitle.defaultProps = {
  children: null,
  mode: DIALOG_TYPES.ADD,
  onClose: null,
  onDelete: null,
  stringIdToAdd: '',
  stringIdToEdit: '',
};

export default DialogTitle;
