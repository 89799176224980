import { css } from 'styled-components';

export default css`
  .sensor-btn {
    cursor: pointer;
    height: 22px;
    line-height: 15px;
    margin: auto 5px;
    text-align: center;
    vertical-align: middle;
  }

  .config-default {
    background-color: #0288d1;
    cursor: default;
  }

  .config-default:hover {
    background-color: #0288d1;
    cursor: default;
  }

  .user-config-custom {
    background-color: #388e3c;
    cursor: default;
  }

  .user-config-custom:hover {
    background-color: #388e3c;
    cursor: default;
  }

  .grid-row .rdg-row .rdg-cell .cell {
    cursor: default !important;
  }
`;
