import { Form, Modal } from 'connex-cds';
import React from 'react';
import { Content } from 'antd/lib/layout/layout';
import { Button, Divider, Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import style from '../utils/style';
import MonacoEditor from '@uiw/react-monacoeditor';

export const SentMessageModal = () => {
  const { values } = Form.useFormContext();
  const { closeModal } = Modal.useModalContext();
  const options = {
    contextmenu: false,
    autoIndent: 'advanced',
    theme: 'vs-dark',
    readOnly: true,
    scrollBeyondLastLine: false,
    minimap: { enabled: false },
    lineNumbers: 'off',
    fontSize: 15,
  };

  const Styled = styled.code`
    ${style}
  `;

  return (
    <Styled>
      <Content>
        <MonacoEditor language="xml" value={values.commandText} height="30vh" options={options} />
      </Content>
      <Divider></Divider>
      <Space>
        <Button key="close" type="primary" onClick={closeModal}>
          <FormattedMessage id={'closeBtn'} defaultMessage={'Close'} />
        </Button>
      </Space>
    </Styled>
  );
};
