import { Form } from 'connex-cds';
import * as Yup from 'yup';

export default {
  path: 'slumValidationParameters',
  labelStringId: 'slumValidationParameters',
  testId: 'slumValidationParameters',
  keyPath: 'crn',
  validationSchema: Yup.object().shape({
    isValidationEnable: Yup.boolean(),
    isDrumSpeedEnable: Yup.boolean(),
    isPatternEnable: Yup.boolean(),
    isEntryExitEnable: Yup.boolean(),
    isCorrelationEnable: Yup.boolean(),
    isSaturationEnable: Yup.boolean(),
    isPressureRangeEnable: Yup.boolean(),
    drumSpeedMargin: Yup.number(),
    maxUPressureRange: Yup.number(),
    maxRSPressureRange: Yup.number(),
    rightSlopePressureCoefficient: Yup.number(),
    leftSLopePressureCoefficient: Yup.number(),
    wShapePressureCoefficient: Yup.number(),
    vShapePressureCoefficient: Yup.number(),
    coefficientOfCorrelation: Yup.number(),
  }),

  fields: [
    {
      path: 'isValidationEnable',
      dataType: Form.FIELD_TYPES.boolean,
      labelStringId: 'isValidationEnable',
      testId: 'isValidationEnable',
      multiline: true,
    },
    {
      path: 'isDrumSpeedEnable',
      dataType: Form.FIELD_TYPES.boolean,
      labelStringId: 'isDrumSpeedEnable',
      testId: 'isDrumSpeedEnable',
      multiline: true,
    },
    {
      path: 'isPatternEnable',
      dataType: Form.FIELD_TYPES.boolean,
      labelStringId: 'isPatternEnable',
      testId: 'isPatternEnable',
      multiline: true,
    },
    {
      path: 'isEntryExitEnable',
      dataType: Form.FIELD_TYPES.boolean,
      labelStringId: 'isEntryExitEnable',
      testId: 'isEntryExitEnable',
      multiline: true,
    },
    {
      path: 'isCorrelationEnable',
      dataType: Form.FIELD_TYPES.boolean,
      labelStringId: 'isCorrelationEnable',
      testId: 'isCorrelationEnable',
      multiline: true,
    },
    {
      path: 'isSaturationEnable',
      dataType: Form.FIELD_TYPES.boolean,
      labelStringId: 'isSaturationEnable',
      testId: 'isSaturationEnable',
      multiline: true,
    },
    {
      path: 'isPressureRangeEnable',
      dataType: Form.FIELD_TYPES.boolean,
      labelStringId: 'isPressureRangeEnable',
      testId: 'isPressureRangeEnable',
      multiline: true,
    },
    {
      path: 'drumSpeedMargin',
      dataType: Form.FIELD_TYPES.number,
      labelStringId: 'drumSpeedMargin',
      testId: 'drumSpeedMargin',
      multiline: true,
    },
    {
      path: 'maxUPressureRange',
      dataType: Form.FIELD_TYPES.number,
      labelStringId: 'maxUPressureRange',
      testId: 'maxUPressureRange',
      multiline: true,
    },
    {
      path: 'maxRSPressureRange',
      dataType: Form.FIELD_TYPES.number,
      labelStringId: 'maxRSPressureRange',
      testId: 'maxRSPressureRange',
      multiline: true,
    },
    {
      path: 'rightSlopePressureCoefficient',
      dataType: Form.FIELD_TYPES.number,
      labelStringId: 'rightSlopePressureCoefficient',
      testId: 'rightSlopePressureCoefficient',
      multiline: true,
    },
    {
      path: 'leftSLopePressureCoefficient',
      dataType: Form.FIELD_TYPES.number,
      labelStringId: 'leftSLopePressureCoefficient',
      testId: 'leftSLopePressureCoefficient',
      multiline: true,
    },
    {
      path: 'wShapePressureCoefficient',
      dataType: Form.FIELD_TYPES.number,
      labelStringId: 'wShapePressureCoefficient',
      testId: 'wShapePressureCoefficient',
      multiline: true,
    },
    {
      path: 'vShapePressureCoefficient',
      dataType: Form.FIELD_TYPES.number,
      labelStringId: 'vShapePressureCoefficient',
      testId: 'vShapePressureCoefficient',
      multiline: true,
    },
    {
      path: 'coefficientOfCorrelation',
      dataType: Form.FIELD_TYPES.number,
      labelStringId: 'coefficientOfCorrelation',
      testId: 'coefficientOfCorrelation',
      multiline: true,
    },
  ],
};
