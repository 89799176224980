import { http } from 'connex-cds';

export default {
  getInsightsWorkbooks: ({ entityRef, queryParams }) =>
    http.get({ apiName: 'assurance', path: `/${entityRef}/insights/insights-workbooks`, queryParams }),
  createInsightsWorkbook: ({ entityRef, data }) =>
    http.post({ apiName: 'assurance', path: `/${entityRef}/insights/insights-workbooks`, data }),
  updateInsightsWorkbook: ({ entityRef, crn, data }) =>
    http.patch({ apiName: 'assurance', path: `/${entityRef}/insights/insights-workbooks/${crn}`, data }),
  deleteInsightsWorkbook: ({ entityRef, crn }) =>
    http.delete({ apiName: 'assurance', path: `/${entityRef}/insights/insights-workbooks/${crn}` }),
  getInsightsWorkbookUrl: ({ entityRef, crn }) =>
    http.get({ apiName: 'assurance', path: `/${entityRef}/insights/insights-workbooks/${crn}/url` }),
};
