import { Grid, Card, CardContent, CardHeader, useTheme } from '@mui/material';
import { useNavigate } from 'react-router';

const InsightsCard = ({ item }) => {
  const navigate = useNavigate();

  return (
    <Grid item xs={3}>
      <Card
        elevation={3}
        variant={'outlined'}
        sx={{
          cursor: 'pointer',
          background: 'transparent',
        }}
        onClick={() => {
          navigate(item.crn);
        }}
      >
        <CardHeader title={<span className={`fa-solid fa-${item.icon} fa-3x`} />} />
        <CardContent>
          <h2>{item.name}</h2>
        </CardContent>
      </Card>
    </Grid>
  );
};

const CardList = ({ items }) => {
  return (
    <Grid container className={'insights-card-list'} spacing={3}>
      {items.map(item => {
        return <InsightsCard item={item} key={item.crn} />;
      })}
    </Grid>
  );
};

export default CardList;
