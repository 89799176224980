import React from 'react';

const DialogManager = ({ children, screen }) => {
  return (
    <div>
      {React.Children.map(children, child => {
        if (child.props?.identifier === screen) {
          return React.cloneElement(child);
        }
        return null;
      })}
    </div>
  );
};

export default DialogManager;
