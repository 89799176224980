import React, { useMemo } from 'react';
import { Localization } from 'connex-cds';

import { GridCell, GridRow } from '@mui/x-data-grid-pro';

import { CaiThemeProvider } from '../../providers';
import NoData from './no-data';
import { NoDataWrapper } from './no-data/styles';
import { DataGridStyled } from './styles';

const FIELDS_TO_HIDE_IN_MANAGE_COLUMNS_FILTER = ['__check__', '__reorder__', 'delete', 'actions'];

const customRow = props => <GridRow data-testid={`row-${props.rowId}`} {...props} />;

const customCell = props => (
  <GridCell
    data-testclass={`cell-column-${props.field}`}
    data-testid={`row-${props.rowId}-column-${props.field}`}
    {...props}
  />
);

const getTogglableColumns = columns =>
  columns.filter(column => !FIELDS_TO_HIDE_IN_MANAGE_COLUMNS_FILTER.includes(column.field)).map(column => column.field);

const EntityTable = ({
  isLoading = true,
  skeletonNumRows = 10,
  rows = [],
  columns = [],
  actionButton,
  isSearching,
  noDataStringId,
  onRefreshData = () => {},
  isDialogTable = false,
  totalRows = 0,
  ...dataGridProps
}) => {
  const translateMessage = Localization.useTranslateMessage();

  const localeText = useMemo(
    () => ({
      columnMenuLabel: translateMessage('general_grid.menu'),
      columnMenuUnsort: translateMessage('general_actions.unsort'),
      columnMenuSortAsc: translateMessage('general_actions.sort_asc'),
      columnMenuSortDesc: translateMessage('general_actions.sort_desc'),
      columnHeaderSortIconLabel: translateMessage('general_actions.sort'),
      columnMenuHideColumn: translateMessage('general_actions.hide_column'),
      columnMenuManageColumns: translateMessage('general_actions.manage_columns'),
      columnsPanelTextFieldLabel: translateMessage('general_actions.find_column'),
      columnsPanelTextFieldPlaceholder: translateMessage('general_grid.column_title'),
      columnsPanelShowAllButton: translateMessage('general_actions.show_all'),
      columnsPanelHideAllButton: translateMessage('general_actions.hide_all'),
      toolbarColumns: translateMessage('general_grid.columns'),
    }),
    [translateMessage]
  );

  if ((!rows || !rows.length) && isDialogTable) {
    return <NoData actionButton={actionButton} isSearching={isSearching} noDataStringId={noDataStringId} />;
  }

  return (
    <CaiThemeProvider>
      <DataGridStyled
        variant="basic"
        className="entity-table"
        disableRowSelectionOnClick
        disableColumnPinning
        disableColumnFilter
        rows={rows}
        columnBuffer={8}
        columns={columns}
        slots={{
          row: customRow,
          cell: customCell,
          ...(!isDialogTable
            ? {
                noRowsOverlay: () => (
                  <NoDataWrapper>
                    <NoData actionButton={actionButton} isSearching={isSearching} noDataStringId={noDataStringId} />
                  </NoDataWrapper>
                ),
              }
            : {}),
        }}
        config={{
          onRefreshDataCallback: onRefreshData,
        }}
        slotProps={{
          columnsPanel: {
            getTogglableColumns,
          },
        }}
        localeText={localeText}
        loading={isLoading}
        {...dataGridProps}
      />
    </CaiThemeProvider>
  );
};

export default EntityTable;
