import Delete from '@mui/icons-material/Delete';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import EditOutlined from '@mui/icons-material/EditOutlined';
import CloseIcon from '@mui/icons-material/Close';

const iconNames = {
  addOutlinedIcon: props => <AddOutlinedIcon {...props} />,
  deleteIcon: props => <Delete {...props} />,
  editOutlinedIcon: props => <EditOutlined {...props} />,
  closeIcon: props => <CloseIcon {...props} />,
};
export default iconNames;
