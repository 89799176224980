import { Form } from 'connex-cds';
import * as Yup from 'yup';

// TODO: Keypath CRN? Confirm and solve it
export default {
  path: 'tabletParameters',
  labelStringId: 'tabletParameters',
  testId: 'tabletParameters',
  keyPath: 'crn',
  validationSchema: Yup.object().shape({
    MasterUnlockCode: Yup.string().required('required'),
    UnlockCode: Yup.string().required('required'),
    SelfDiagnosticsUnlockCode: Yup.string().required('required'),
    AWSAccessKeyId: Yup.string().required('required'),
    AWSSecretAccessKey: Yup.string().required('required'),
    AWSUrlAccess: Yup.string(),
  }),

  fields: [
    {
      path: 'MasterUnlockCode',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'MasterUnlockCode',
      testId: 'MasterUnlockCode',
      multiline: true,
    },
    {
      path: 'UnlockCode',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'UnlockCode',
      testId: 'UnlockCode',
      multiline: true,
    },
    {
      path: 'SelfDiagnosticsUnlockCode',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'SelfDiagnosticsUnlockCode',
      testId: 'SelfDiagnosticsUnlockCode',
      multiline: true,
    },
    {
      path: 'AWSAccessKeyId',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'AWSAccessKeyId',
      testId: 'AWSAccessKeyId',
      multiline: true,
    },
    {
      path: 'AWSSecretAccessKey',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'AWSSecretAccessKey',
      testId: 'AWSSecretAccessKey',
      multiline: true,
    },
    {
      path: 'AWSUrlAccess',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'AWSUrlAccess',
      testId: 'AWSUrlAccess',
      multiline: true,
    },
  ],
};
