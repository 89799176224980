import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const TableContainerStyled = styled.main`
  ${({ theme }) => css`
    display: grid;
    grid-template-rows: auto 1fr;
    overflow: hidden;
    gap: 16px;
    height: 100%;
  `}
`;
