import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Localization } from 'connex-cds';

import { Close, Search } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';

import { useDebounce } from '../../hooks/useDebounce';
import { normalizeText } from './searchFilter.helpers';
import { SearchBarStyled, SearchContainer } from './styles';

const SearchFilter = ({ onSearchChange, disabled, resultsCount, fullWidth }) => {
  const [value, setValue] = useState('');
  const searchRef = useRef(null);
  const translateMessage = Localization.useTranslateMessage();
  const debouncedSearch = useDebounce(value, 200);

  useEffect(() => {
    onSearchChange(normalizeText(debouncedSearch));
  }, [debouncedSearch, onSearchChange]);

  const handleInputChange = useCallback(
    e => {
      const inputValue = e.target?.value;
      if (inputValue.length <= 50) {
        setValue(inputValue);
      }
    },
    [setValue]
  );

  const handleInputClear = useCallback(() => setValue(''), [setValue]);

  return (
    <SearchContainer>
      <SearchBarStyled
        inputRef={searchRef}
        fullWidth={fullWidth}
        variant="outlined"
        placeholder={translateMessage('search')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search onClick={() => searchRef.current.focus()} sx={{ fontSize: 24 }} />
            </InputAdornment>
          ),
          endAdornment: value && (
            <InputAdornment position="end">
              <Close onClick={handleInputClear} sx={{ fontSize: 24, cursor: 'pointer' }} />
            </InputAdornment>
          ),
        }}
        inputProps={{
          'data-testid': 'search-bar',
        }}
        onKeyDown={e => {
          if (e.key === 'Escape') {
            searchRef.current.blur();
          }
        }}
        disabled={disabled}
        onChange={handleInputChange}
        value={value}
      />
      {resultsCount ? (
        <Localization.Translate
          stringId="searchFilter_resultsCount"
          data-testid="row-count"
          values={{
            count: resultsCount,
          }}
        />
      ) : null}
    </SearchContainer>
  );
};

export default SearchFilter;
